<!--
 * @Author: ZBH
 * @Date: 2021-01-24 16:43:00
 * @LastEditors: tangshuo
 * @LastEditTime: 2021-12-24 16:28:24
 * @Remarks:
-->

<template>
  <div class="accountSet-main">
    <header class="account-header-w flex flex-js">
      <div class="account-header">
        <i class="iconfont icon_arrow_left" @click="closeAddDig"></i>
        <span>换肤</span>
      </div>
    </header>
    <div class="tab-list">
      <div class="set-default-skin" v-if="isSetAdminAuth" style="margin-bottom: 48px">
        <span class="set-title">本店所有账号默认展示风格：</span>
        <el-select
            class="con-select skin-select"
            @change="setBranchIniFileStyle"
            v-model="selSkin"
        >
          <el-option label="简约版" value="Min"></el-option>
          <el-option label="简约版Plus" value="Min_Plus"></el-option>
          <el-option label="经典版" value="Classic"></el-option>
          <el-option label="深色版" value="Dark"></el-option>
          <el-option label="深色版Plus" value="Plus"></el-option>
        </el-select>
      </div>
      <div class="tab-change-title">您要将当前账号风格切换至：</div>
      <div
        class="tab-item flex flex-b"
        :class="{
          'item-active': curSkin == item.key,
        }"
        @click="selectSkin(item)"
        v-for="(item, index) in tabList"
        :key="index"
      >
        <div class="flexWidth">
          <div class="tab-value">{{ item.value }}</div>
          <div class="tab-remark">{{ item.remark }}</div>
        </div>
        <i class="iconfont btn_sel"></i>
      </div>
    </div>
    <el-button
      class="con-button flex"
      v-if="false"
      type="primary"
      @click="switchSkin()"
      >确定</el-button
    >
  </div>
</template>

<script>
// import ReInfo from './registerInfo';
import {mapState} from "vuex";

export default {
  data() {
    return {
      tabList: [
        {
          value: "简约",
          remark: "MINIMALIST MODE",
          key: "Min",
        },
        {
          value: "简约版PLUS",
          remark: "MINIMALIST MODE PLUS",
          key: "Min_Plus",
        },
        {
          value: "经典",
          remark: "CLASSIC MODE",
          key: "Classic",
        },
        {
          value: "深色",
          remark: "DARK MODE",
          key: "Dark",
        },
        {
          value: "深色PLUS",
          remark: "DARK MODE PLUS",
          key: "Plus",
        }
      ],
      selSkin: "",
      curSkin: "Classic",
      isSetAdminAuth: false, //是否有管理员权限
      isSetAuth: false, //是否有权限
    };
  },
  props: {
    registerStatus: {
      type: Object, //授权  Status 0未授权 1已授权 2审核中 3审核通过 4不通过 5代码发布审核延后 6已发布
      default: () => {},
    },
    isShowChangeSkin: {
      type: Boolean,
      default: false
    }
  },
  components: {
    // ReInfo
  },
  // computed: {
  //   ...mapState({
  //     theme: (state) => state.theme,
  //   }),
  // },
  watch: {
    theme(val) {
      this.curSkin = val || "Min";
    },
    isShowChangeSkin(val) {
      if (!val) return;
      this.curSkin = this.$store.state.theme || "Min";
    }
  },
  mounted() {
    let selSkin =
      sessionStorage.getItem("DSkin") ||
      localStorage.getItem("DSkin") ||
      "Classic";
    this.selSkin = selSkin;
    this.curSkin = this.$store.state.theme || "Min";
    this.getAuthorizeByIds();
    this.getAuthorizeByIds1();
  },
  methods: {
    selectSkin(item) {
      if (item.disabled && !this.isSetAuth) {
        this.$Message("暂未开放,敬请期待");
        return;
      } else {
        this.curSkin = item.key;
      }
      this.switchSkin();
    },
    /**
     *获取是否有深色版权限
     */
    getAuthorizeByIds() {
      this.$axios({
        method: "POST",
        // url: "NewWeddingApi/AuthorizeModule/Authorize/GetAuthorizeByIds",
        url: 'NewWeddingApi/AuthorizeModule/Authorize/IsAuth',
        data: {
          itemid: "450D89F8-1377-8279-9C3F-6547A2863AD4",
        },
        success(res) {
          if (res.data.status) {
            // let userList = res.data.data;
            // this.isSetAuth = !!userList.find(
            //   (u) => u.userid == sessionStorage.getItem("UserId")
            // );
            this.isSetAuth = !!res.data.data;
          } else {
            this.$Error(res.data.msg ? res.data.msg : "操作失败");
          }
        },
        error(err) {
          console.log(err);
          this.$Error("操作失败");
        },
      });
    },
    /**
     *获取是否有管理员权限
     */
    getAuthorizeByIds1() {
      this.$axios({
        method: "POST",
        // url: "NewWeddingApi/AuthorizeModule/Authorize/GetAuthorizeByIds",
        url: 'NewWeddingApi/AuthorizeModule/Authorize/IsAuth',
        data: {
          itemid: "9951556E-3026-48EC-AF2E-0600CA066C3B",
        },
        success: (res) => {
          if (res.data.status) {
            // let userList = res.data.data;
            // this.isSetAdminAuth = !!userList.find(
            //   (u) => u.userid == sessionStorage.getItem("UserId")
            // );
            // sessionStorage.setItem('isSetAuth', this.isSetAdminAuth);

            this.isSetAdminAuth = !!res.data.data;
            sessionStorage.setItem('isSetAuth', this.isSetAdminAuth);
          } else {
            this.$Error(res.data.msg ? res.data.msg : "操作失败");
          }
        },
        error(err) {
          console.log(err);
          this.$Error("操作失败");
        },
      });
    },
    /**
     * 设置店铺默认主题
     */
    setBranchIniFileStyle() {
      this.$axios({
        method: "POST",
        url: "NewWeddingApi/SystemManage/Branch/SetBranchIniFileStyle",
        data: { Value: this.selSkin },
        success(res) {
          if (res.data.status) {
            this.$Success("操作成功");
            sessionStorage.setItem("DSkin", this.selSkin);
            localStorage.setItem("DSkin", this.selSkin);
          } else {
            this.$Error(res.data.msg ? res.data.msg : "操作失败");
          }
        },
        error(err) {
          console.log(err);
          this.$Error("操作失败");
        },
      });
    },
    /**
     * 切换主题
     */
    switchSkin() {
      //切换到经典页面
      if (this.curSkin === "Classic") {
        this.$store.state.theme = "Classic";
      } else if (this.curSkin === "Min") {
        this.$store.state.theme = "Min";
        // this.closeAddDig();
      } else if (this.curSkin === 'Min_Plus') {
        this.$store.state.theme = 'Min_Plus';
      }  else {
        this.$store.state.theme = this.curSkin;
        // this.closeAddDig();
      }
      sessionStorage.setItem("insercell", JSON.stringify(this.$store.state));
      const urlObj = this.$getUrlPrefix(null,false);
      if (this.curSkin === "Classic") {
        window.location.href = `${urlObj['1.0']}case?v=${urlObj.version || '1.0'}`;
      }
      this.$forceUpdate();
    },
    //返回
    closeAddDig() {
      this.$emit("closeAddDig");
    },
  },
};
</script>

<style scoped lang="scss">
.accountSet-main {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  @include themify($themes) {
    background: themed("main-bg");
  }
  z-index: 3;

  .account-header-w {
    border: 0px solid transparent;
  }

  .tab-item {
    padding: 20px 24px !important;
    margin: 12px 24px 24px 24px;
    border: 1px solid #eeeeee;
    @include themify($themes) {
      border: 1px solid themed("hover-bg");
      border-radius: themed("border-radius");
    }
    box-sizing: border-box;
    .tab-value {
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      line-height: 36px;
      margin-bottom: 4px;
      @include themify($themes) {
        color: themed("set-item-color");
      }
    }
    .tab-remark {
      font-size: 14px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #666666;
      line-height: 20px;
    }
    .btn_sel {
      opacity: 0;
      @include themify($themes) {
        color: themed("search-btn-bg");
      }
    }
  }

  .item-active {
    position: relative;
    border-color: transparent;
    &::before {
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;
      border: 2px solid #222222;
      @include themify($themes) {
        border: 2px solid themed("search-btn-bg");
        border-radius: themed("border-radius");
      }
    }
    .tab-value {
      @include themify($themes) {
        color: themed("con-btn-border");
      }
    }
    .btn_sel {
      opacity: 1;
    }
  }

  .tab-info {
    &:hover {
      background: #ffffff !important;
      @include themify($themes) {
        background: themed("price-bg") !important;
      }
    }
  }
  .con-button {
    position: absolute;
    bottom: 0;
    width: 352px;
    margin: 24px;
    text-align: center;
    height: 48px;
    ::v-deep span {
      font-size: 14px;
    }
  }
  .set-default-skin {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    .set-title {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      margin: 24px 0 12px;
    }
    ::v-deep {
      .skin-select .el-input .el-input__inner {
        @include themify($themes) {
          border-radius: themed("border-radius-min");
        }
      }
    }
  }
}
.tab-change-title {
  padding: 0 24px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  margin: 24px 0 12px;
}
.tab-list {
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
}
</style>
