<!--
 * @Author: ZBH
 * @Date: 2021-01-24 16:43:00
 * @LastEditors: tangshuo
 * @LastEditTime: 2022-01-17 12:06:03
 * @Remarks:
-->

<template>
  <!-- <div class="set-w"> -->
  <div class="accountSet-main">
    <div
      style="width: 100%; height: 100%; z-index: 3"
      class="main-box"
      v-loading="loading"
      @click="isShowQrCodeDig = false"
    >
      <header class="account-header-w flex flex-js">
        <div class="account-header">
          <i class="iconfont icon_arrow_left" @click="closeAddDig"></i>
          <span>账号设置</span>
        </div>
      </header>
      <div class="account-info">
        <el-image
          class="account-logo"
          :src="branchInfo.UserHeadImgSrc"
          fit="cover"
        ></el-image>
        <div class="account-name flex flex-js">
          {{ branchInfo.UserName }}
          <i class="iconfont icon_edit" @click="handlerUpdateEdit('Edit')"></i>
        </div>
        <div class="account-tel">{{ branchInfo.PhoneNum }}</div>
      </div>
      <div class="tab-line"></div>
      <div class="tab-list">
        <div class="tab-item tab-info">
          <div class="name">
            <span>公司</span>
            {{ branchInfo.BranchName }}
          </div>
        </div>
        <div class="tab-item tab-info">
          <div class="name">
            <span>部门</span>
            {{ branchInfo.DepartmentName }}
          </div>
        </div>
        <div class="tab-item tab-info">
          <div class="name">
            <span>微信</span>
            {{bingWxInfo && bingWxInfo.openid ? bingWxInfo.nickname : "未绑定" }}
          </div>
          <span
            class="bind"
            @click.stop="isShowTip = true"
            v-if="bingWxInfo && bingWxInfo.openid"
            >更换</span
          >
          <span class="bind" v-else @click.stop="handlerGetQRcode()">绑定</span>
        </div>
      </div>
      <div class="tab-line"></div>
      <div class="tab-list">
        <el-popover
          placement="left-end"
          :visible-arrow="false"
          popper-class="xcx-menu-popover"
          :open-delay="200"
          :offset="-25"
          trigger="hover"
        >
          <div class="xcx-menu-box">
            <span
              class="menu-item"
              :class="{ 'show-item': branch.BranchId == branchId }"
              v-for="branch of selBranchList"
              :key="branch.BranchId"
              :title="branch.BranchName"
              @click="switchBranch(branch)"
              ><i class="iconfont icon_gou2"></i>{{ branch.BranchName }}
            </span>
          </div>
          <div slot="reference" class="tab-item flex flex-b">切换店铺</div>
        </el-popover>
        <div
          class="tab-item flex flex-b"
          v-for="(item, index) in tabList"
          :key="index"
          @click="openAccountSet(item.value)"
        >
          {{ item.value }}
          <i class="iconfont icon_arrow_right"></i>
        </div>
      </div>
      <div class="tab-line"></div>
    </div>
    <!-- 修改头像 -->
    <div
      class="edit-user"
      v-if="Edit.dialogVisible"
      v-loading="isEditLoading"
      :class="[Edit.isActive ? 'edit-user-show' : 'edit-user-hide']"
    >
      <div class="edit-logo" @click="openCaseFile">
        <el-image
          class="edit-logo"
          :src="cosIp + headImg"
          fit="cover"
        ></el-image>
        <div class="edit-logo-mask flex">
          <i class="iconfont icon_add3"></i>
        </div>
      </div>
      <!-- no-border-input -->
      <el-input
        class="banner-input con-input"
        type="text"
        disabled
        placeholder="请输入"
        v-model="userName"
        maxlength="40"
      ></el-input>
      <div class="edit-foote flex">
        <el-button
          class="edit-btn con-button flex"
          type="primary"
          @click="updateUserBasicPhone()"
          >确定</el-button
        >
        <el-button
          class="edit-btn cancel-button flex"
          type="primary"
          @click="handlerPersonals()"
          >取消</el-button
        >
      </div>
    </div>
    <!-- 修改头像 end-->
    <!-- 修改密码 -->
    <div
      v-if="EditPas.dialogVisible"
      class="editpas-pas"
      :class="[EditPas.isActive ? 'editpas-user-show' : 'editpas-user-hide']"
    >
      <div class="edit-pw-tit flex flex-b">
        <div>
          <span class="nav-item" :class="{'active': EditPas.navActive === 0}" @click="tabChangePsd(0)">手机号验证修改</span>
          <span class="nav-item" :class="{'active': EditPas.navActive === 1}" @click="tabChangePsd(1)">旧密码验证修改</span>
        </div>
        <i class="iconfont icon_close" @click="handlerPersonals"></i>
      </div>
      <el-input
        class="banner-input con-input"
        type="text"
        placeholder="请输入电话号码"
        readonly
        v-model="phoneNum"
      ></el-input>
      <div class="input-yzm flex" v-if="EditPas.navActive === 0">
        <el-input
          class="banner-input con-input"
          type="text"
          placeholder="输入验证码"
          v-model="yzmCode"
        ></el-input>
        <div class="yzm-btn flexWidth" @click="handlerGetMsgCode">
          {{ yzmTime === 121 ? "获取验证码" : `${yzmTime}S` }}
        </div>
      </div>
      <el-input
          v-if="EditPas.navActive === 1"
          class="banner-input con-input"
          type="text"
          placeholder="输入旧密码"
          v-model="oldPassWords"
          show-password
      ></el-input>
      <el-input
        class="banner-input con-input"
        type="text"
        placeholder="设置新密码"
        v-model="passWord"
        show-password
      ></el-input>
      <div class="edit-foote flex flex-js">
        <el-button
          class="edit-btn con-button flex"
          type="primary"
          @click="handlerSavePas()"
          >确定</el-button
        >
        <el-button
          class="edit-btn cancel-button flex"
          type="primary"
          @click="handlerPersonals()"
          >取消</el-button
        >
      </div>
    </div>
    <!-- 修改密码 end-->
    <!--绑定二维码-->
    <div v-if="isShowQrCodeDig" class="q-rcode" @click.stop>
      <p class="qrcode-text">请使用新的微信号扫码下方二维码</p>
      <p class="qrcode-text">重新绑定：</p>
      <div class="qrcode-content">
        <img :src="qRcodeUrl" alt />
      </div>
    </div>
    <!-- </div> -->
    <input
      type="file"
      id="file"
      ref="headImg"
      accept="image/png, image/jpeg"
      style="opacity: 0; width: 0; height: 0; position: absolute"
      @change="selCaseImg"
    />
    <TailorCoverDig
      :isShowTailor="isShowTailor"
      :imgSrc="imgSrc"
      :pathKey="pathKey"
      :isImg="false"
      :fixedNumber="[1, 1]"
      @uploadCallback="uploadCallback"
      @closeTailorDig="isShowTailor = false"
    ></TailorCoverDig>
    <ConTip
      :isShowTip="isShowTip"
      type="warn"
      tipText="确定要更换所绑微信号吗？"
      lessText="更换会解除当前绑定，且必须绑定新的微信号哦"
      @confirmClick="replaceBing"
      @closeTipDig="isShowTip = false"
      @cancelClick="isShowTip = false"
    ></ConTip>
  </div>
</template>

<script>
// import ReInfo from './registerInfo';
import {getCaseDisplayMode, setCaseDisplayMode} from "@/assets/js/utils/caseDiplayMode"
import TailorCoverDig from "@/components/tailoring/tailoringCoverDig";
import { getUpdateUserBasicPhone } from "@/api/base";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      isShowTip: false, //是否显示提示
      isShowTailor: false, //是否显示裁剪
      imgSrc: "",
      pathKey: "",
      name: "",
      phoneNum: "", //账号
      yzmCode: "", //验证码
      passWord: "", //新密码
      oldPassWords: '',
      yzmTime: 121, //倒计时
      yzmInterval: null, //验证码计时器
      qrCodeInterval: null, //验证码计时器
      ResIndex: 0,
      isEditLoading: false,
      branchId: sessionStorage.getItem("BranchId"),
      tabList: [
        {
          value: "修改密码",
        },
        {
          value: "退出登录",
        },
      ],
      selBranchList: [], //店铺列表
      loading: false,
      Edit: {
        dialogVisible: false,
        isActive: true,
        LogoUrl: "", //二进制头像
        UserName: "",
      },
      EditPas: {
        navActive: 0,
        dialogVisible: false,
        isActive: true,
        NewPassWord: "", //	是	 验证码
        NewPassWords: "", //	是	int密码
        oldPassWords: '',
      },
      headImg: "",
      userName: "",
      cosIp: sessionStorage.getItem("BranchCosCdnUrl_B"),
      qRcodeUrl: "", //二维码地址
      qrCode: "", //二维码状态码
      bingWxInfo: {}, //绑定的微信信息
      isShowQrCodeDig: false, //是否显示绑定二维码
    };
  },
  props: {
    registerStatus: {
      type: Object, //授权  Status 0未授权 1已授权 2审核中 3审核通过 4不通过 5代码发布审核延后 6已发布
      default: () => {},
    },
  },
  computed: {
    ...mapState({
      branchInfo: (state) => {
        return state.base.branchInfo;
      },
    }),
  },
  watch: {
    isShowQrCodeDig(val) {
      this.loading = val;
    },
  },
  components: {
    // ReInfo
    TailorCoverDig,
  },
  mounted() {
    this.handlerGetWXUser();
    this.getBranchSelect();
  },
  methods: {
    ...mapActions({
      getGetUserInfo: "base/getGetUserInfo",
    }),
    tabChangePsd(val) {
      this.EditPas.navActive = val;
      this.EditPas.NewPassWord = '';
      this.EditPas.NewPassWords = '';
      this.EditPas.oldPassWords = '';
      this.passWord = '';
      this.oldPassWords = '';
      this.yzmCode = '';
      if (this.yzmInterval) {
        clearInterval(this.yzmInterval);
        this.yzmInterval = null;
        this.yzmTime = 121;
      }
    },
    /**
     * 切换店铺
     */
    switchBranch(branch) {
      if (branch.BranchId == sessionStorage.getItem("BranchId")) {
        this.$Message("该店铺为当前所在店铺");
        return;
      }
      this.loginLoading = true;
      this.$axios({
        method: "POST",
        url: "NewWeddingApi/UserLogin/LoginMethod/UserLogin/ChangeUserBranch",
        data: {
          id: sessionStorage.getItem("UserId"), //	是	int	用户ID
          selectid: branch.BranchId, //	是	int	选中店ID
          device: 0, //是	int	pc传0
        },
        success(res) {
          if (res.data.status) {
            //记住选择的酒店精选非精选
            let showType = sessionStorage.getItem("hotelType") == "true";
            //初始化vuex中的权限信息及Storage中的信息
            let Tablet = sessionStorage.getItem('Tablet');
            let ismac = localStorage.ismac != undefined && localStorage.ismac == "1" ? localStorage.ismac : '';
            const switchDisplayMode = getCaseDisplayMode('all'); // 获取案例列表展示方式
            sessionStorage.clear();
            localStorage.clear();
            localStorage.setItem("ismac", ismac);
            if (Tablet) sessionStorage.setItem('Tablet',Tablet);
            setCaseDisplayMode(switchDisplayMode, 'all'); // 存储案例列表展示方式
            sessionStorage.setItem("hotelType", showType);
            this.$store.state.macPath = "";
            this.$store.state.warehouse = {
              keyName: '',
              label_shopId: 0, // 筛选 标签的店铺ID
              label: [],
              displayMode: -1, // 0 横图 、 1 竖图 2 表格
              fields: {}, // 字段
            }
            this.$store.state.discoverAuth = [];
            // this.$store.state.authList = [];
            // this.$store.state.authButton = {};
            this.$store.state.userInfo = null;
            this.loginData = res.data;
            sessionStorage.setItem("isOutLogin", this.loginData.loginData);
            sessionStorage.setItem("UserName", this.loginData.nowusername);
            sessionStorage.setItem("jwtStr", this.loginData.jwtStr);
            localStorage.setItem("jwtStr", this.loginData.jwtStr);
            this.analysisAuthHead();
          } else {
            this.loginLoading = false;
            this.$Message(
              res.data.msg ? res.data.msg : "切换店铺失败",
              "error"
            );
          }
        },
        error(err) {
          console.log(err);
        },
      });
    },
    //请求头解析
    async analysisAuthHead() {
      let authInfo = this.loginData;
      //储存请求头信息
      let headStr = authInfo.Authorization;
      sessionStorage.setItem("Authorization", headStr);
      localStorage.setItem("Authorization", headStr);
      sessionStorage.setItem("hostAuthStr", authInfo.AuthorizationStr);
      localStorage.setItem("hostAuthStr", authInfo.AuthorizationStr);

      if (authInfo.AuthorizationStr) {
        let baseUserId = authInfo.AuthorizationStr.split("|")[0];
        sessionStorage.setItem("baseUserId", baseUserId);
      }

      //储存解析后的请求头信息
      let authList = headStr.split("NewWedding ")[1].split(",");
      if (authList.length > 0) {
        authList.forEach((auth) => {
          if (auth.split("=")[0] != "") {
            sessionStorage.setItem(
              auth.split("=")[0],
              auth.split("=")[1] ? auth.split("=")[1] : ""
            );
            localStorage.setItem(
              auth.split("=")[0],
              auth.split("=")[1] ? auth.split("=")[1] : ""
            );
          }
        });
      }
      //储存店铺信息
      for (let key in authInfo.data) {
        sessionStorage.setItem(key + "_B", authInfo.data[key]);
        localStorage.setItem(key + "_B", authInfo.data[key]);
      }
      //存储im所需id
      sessionStorage.setItem("IMUserId", authInfo.IMUserId);
      localStorage.setItem("IMUserId", authInfo.IMUserId);

      // 切换店铺肤色改变为当前店铺的默认肤色
      const theme = authInfo.userDetail.LookStyle || "Classic";
      sessionStorage.setItem('switchShopSkin', theme);

      // 默认肤色
      sessionStorage.setItem(
          "DSkin",
          authInfo.userDetail.LookStyle || "Classic"
      );
      localStorage.setItem("DSkin", authInfo.userDetail.LookStyle || "Classic");

      this.$store.newCos = window.cos;
      await this.getUserAuth(1);
      sessionStorage.setItem("insercell", JSON.stringify(this.$store.state));

      const urlObj = this.$getUrlPrefix(null,false);

      // 经典版
      if (theme === 'Classic' && process.env.NODE_ENV !== 'development') {
        window.location.href = `${urlObj['1.0']}case?v=${urlObj.version || '1.0'}`;
      } else {
        // 2.0
        location.reload();
      }
    },
    /**
     * 获取用户权限 并进入页面
     */
    getUserAuth(type = 1) {
      return new Promise((resolve, reject) => {
        this.$axios({
          url: 'NewWeddingApi/SystemModule/Module/Module/GetHomeModuleTreeNeedChild',
          method: 'POST',
          data: {
            moduleId: type === 1 ? '' : 0,
            category: type
          },
          success(res) {
            if (res.data.status) {
              if (type === 1) this.$store.state.authList = res.data.data;
              resolve(res.data.data);
            } else {
              this.$Message(res.data.msg, 'error');
              reject();
            }
          },
          error() {
            reject();
          }
        });
      });
    },
    /**
     * 打开文件选择
     */
    openCaseFile() {
      this.$refs.headImg.click();
      this.$refs.headImg.value = "";
    },
    /**
     * 获取店铺切换列表
     */
    getBranchSelect() {
      this.$axios({
        method: "GET",
        url: "NewWeddingApi/Organization/BranchRelation/GetBranchSelect",
        data: { strid: sessionStorage.getItem("UserBranchIds") },
        success(res) {
          if (res.data.status) {
            this.selBranchList = res.data.data || [];
          }
        },
        error(err) {
          console.log(err);
        },
      });
    },
    /**
     * 选择了图片后
     */
    selCaseImg() {
      let imgNews = this.$refs.headImg.files[0];
      this.imgSrc = this.$getObjectURL(imgNews);
      this.pathKey = `${sessionStorage.getItem(
        "BranchId"
      )}/${sessionStorage.getItem("UserId")}/HeadImg/`;
      this.isShowTailor = true;
    },
    /**
     * 裁剪回调
     */
    uploadCallback(keypath) {
      this.isShowTailor = false;
      //   this.updateUserBasicPhone(keypath);
      this.headImg = keypath;
    },
    //修改店铺资料
    updateUserBasicPhone() {
      let data = {
        HeadImg: this.headImg,
        UserName: this.userName,
        UserId: this.branchInfo.UserId,
      };
      this.isEditLoading = true;
      getUpdateUserBasicPhone(data)
        .then((res) => {
          this.isEditLoading = false;
          if (res.status) {
            this.$Success(res.msg);
            this.getGetUserInfo();
            this.handlerPersonals();
          } else {
            this.$Error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.isEditLoading = false;
        });
    },
    /**
     * 更改绑定
     */
    replaceBing() {
      this.$axios({
        method: "get",
        url: "NewWeddingApi/UserInfoMnage/GZHManager/GZHUser/UnboundWxUser",
        success(res) {
          this.isShowTip = false;
          if (res.data.status) {
            this.handlerGetQRcode();
          } else {
            this.$Error("解除失败!请重新绑定");
          }
        },
      });
    },
    //获取二维码
    handlerGetQRcode() {
      this.loading = true;
      this.isShowQrCodeDig = true;
      this.$axios({
        method: "get",
        url: "NewWeddingApi/UserInfoMnage/GZHManager/GZHUser/GetUserQRCodeUrl",
        success(res) {
          if (res.status) {
            this.qRcodeUrl = res.data.data;
            this.qrCode = res.data.Code;
            this.handlerQRcodeCallBack();
          } else {
            this.$Error("获取二维码失败!");
          }
        },
      });
    },
    //绑定二维码回调
    handlerQRcodeCallBack() {
      if (this.qrCodeInterval) {
        clearInterval(this.qrCodeInterval);
      }

      this.qrCodeInterval = setInterval(() => {
        this.$axios({
          method: "get",
          url: "NewWeddingApi/UserInfoMnage/GZHManager/GZHUser/GetQRLoginStatu",
          data: { Code: this.qrCode },
          success(res) {
            this.ResIndex++;
            if (res.data.status) {
              if (res.data.CodeStatus) {
                this.$Success("绑定成功!");
                setTimeout(() => {
                  this.isShowQrCodeDig = false;
                }, 1000);
                clearInterval(this.qrCodeInterval);
                this.handlerGetWXUser();
                this.ResIndex = 0;
              } else {
                // console.log('待扫描')
              }
            } else {
              if (this.ResIndex == 1) {
                clearInterval(this.qrCodeInterval);
                this.$Error("生成二维码失败!");
              }
            }
          },
        });
      }, 1000);
    },
    //是否已绑定微信号
    handlerGetWXUser() {
      this.$axios({
        method: "get",
        url: "NewWeddingApi/UserInfoMnage/GZHManager/GZHUser/GetNowUserBindWxUser",
        success(res) {
          if (res.data.status) {
            this.bingWxInfo = res.data.data;
          }
        },
      });
    },
    //关闭操作
    handlerPersonals() {
      let arr = ["Edit", "EditPas"];
      arr.forEach((res) => (this[res].isActive = false));
      setTimeout(() => {
        arr.forEach((res) => (this[res].dialogVisible = false));
        this.loading = false;
        this.yzmCode = "";
        this.passWord = "";
        if (this.yzmInterval) {
          clearInterval(this.yzmInterval);
          this.yzmTime = 121;
        }
      }, 300);
    },
    openAccountSet(val) {
      if (val == "修改密码") {
        this.phoneNum = this.branchInfo.PhoneNum;
        this.handlerUpdateEdit("EditPas");
      } else {
        this.handlerUserExit();
      }
    },
    //获取验证码
    handlerGetMsgCode() {
      if (this.yzmTime < 121) {
        this.$Message("请不要频繁获取");
        return;
      }
      this.$axios({
        method: "post",
        url: "NewWeddingApi/BackAdminManage/BaseBranchManage/BranchInfo/SendUpdatePWMsg",
        success: (res) => {
          if (res.data.status) {
            this.$Success("验证码下发成功!");
            if (!this.yzmInterval) {
              this.yzmTime = 121;
              this.yzmInterval = setInterval(() => {
                this.yzmTime--;
                if (this.yzmTime == 0) {
                  this.yzmTime = 121;
                  clearInterval(this.yzmInterval);
                }
              }, 1000);
            }
          } else {
            this.$Error("获取验证码失败!");
          }
        },
      });
    },
    //修改密码
    handlerSavePas() {
      if (!this.yzmCode && this.EditPas.navActive === 0) {
        this.$Error("验证码不可为空");
        return;
      }
      if (!this.oldPassWords.length && this.EditPas.navActive === 1) {
        this.$Error("请输入旧密码");
        return;
      }
      if (this.passWord.length < 6 || this.passWord.length > 12) {
        this.$Error("密码长度为6-12");
        return;
      }
      if (this.EditPas.navActive === 0) {
        let parms = {
          YZMCode: this.yzmCode, //	是	int	验证码
          NewPassword: this.passWord, //	是	int	新密码
        };
        this.$axios({
          method: "post",
          url: "NewWeddingApi/BackAdminManage/BaseBranchManage/BranchInfo/UpdateUserPw",
          data: parms,
          success(res) {
            if (res.data.status) {
              this.$Success("修改成功");
              this.handlerPersonals();
            } else {
              this.$Error(res.data.msg ? res.data.msg : "修改失败");
            }
          },
        });
      } else {
        let parms = {
          OldPassword: this.oldPassWords, //	是	int	旧密码
          NewPassword: this.passWord, //	是	int	新密码
        };
        this.$axios({
          method: "post",
          url: "NewWeddingApi/BackAdminManage/BaseBranchManage/BranchInfo/UpdateUserPwByOldPwd",
          data: parms,
          success(res) {
            if (res.data.status) {
              this.$Success("修改成功");
              this.handlerPersonals();
            } else {
              this.$Error(res.data.msg ? res.data.msg : "修改失败");
            }
          },
        });
      }
    },
    //修改弹窗
    handlerUpdateEdit(type) {
      // EditPas : 更新密码 edit:编辑头像电话  EeitOut: 退回账号
      this.loading = true;
      // if (type === "EditPas" && !this.$verifPhone(this.UserInfo.PhoneNum)) {
      //   this.$Message("当前账号不支持修改密码", "warning");
      //   return;
      // }

      if (type == "Edit") {
        this.headImg = this.branchInfo.UserHeadImg;
        this.userName = this.branchInfo.UserName;
      }

      if (type == "EditPas") {
        this.EditPas.navActive = 0;
        this.EditPas.NewPassWord = '';
        this.EditPas.NewPassWords = '';
        this.EditPas.oldPassWords = '';
        this.passWord = '';
        this.oldPassWords = '';
      }
      this[type].dialogVisible = true;
      this[type].isActive = true;
    },
    /**
     * 退出登录
     */
    handlerUserExit() {
      setTimeout(() => {
        let loginUrl = sessionStorage.getItem("loginUrl");
        if (loginUrl) {
          //初始化vuex中的权限信息及Storage中的信息
          let Tablet = sessionStorage.getItem('Tablet');
          let ismac = localStorage.ismac != undefined && localStorage.ismac == "1" ? localStorage.ismac : '';
          //初始化vuex中的权限信息及Storage中的信息
          sessionStorage.clear();
          localStorage.clear();
          if (Tablet) sessionStorage.setItem('Tablet',Tablet);
          localStorage.setItem("ismac", ismac);
          this.$store.state.macPath = "";
          this.$store.state.authList = [];
          this.$store.state.userInfo = null;
          this.$store.state.authButton = {};
          this.$store.state.userPhone = "";
          this.$store.state.headList = [];
          this.$router.push(loginUrl);
        } else {
          const urlObj = this.$getUrlPrefix(null,false);
          /**
           * 解决壳子 退出登录后不能缓存到ismac的bug
           * ismac
           *      有值 ，退出登录
           *          this.$router.push({
           *             path: '/',
           *             query: {
           *               'ismac': isMacVal
           *             }
           *           })
           *
           *       无值，退出登录  this.$router.push("/");
           *
           */
          const isMacVal = localStorage.ismac != undefined && localStorage.ismac == "1";
          if (process.env.NODE_ENV === "development") {
            this.$router.push("/login");
          } else {
            if (!isMacVal) {
              window.location = `${urlObj['1.0']}?v=${urlObj.version || '1.0'}`;
            } else {
              window.location = `${urlObj['1.0']}?ismac=${localStorage.ismac}&v=${urlObj.version || '1.0'}`;
            }
          }
        }
      }, 500);
    },
    //返回
    closeAddDig() {
      this.$emit("closeAddDig");
    },
    registSubmit() {
      this.$emit("submit");
    },
  },
};
</script>

<style scoped lang="scss">
// .set-w {
.accountSet-main {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  @include themify($themes) {
    background: themed("search-bg");
  }
  z-index: 3;
  .main-box::v-deep .el-loading-spinner {
    display: none;
  }
  .account-info {
    padding: 24px 24px 12px 24px;
    .account-logo {
      width: 64px;
      height: 64px;
      border-radius: 100%;
      margin-bottom: 16px;
    }
    .account-name {
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      @include themify($themes) {
        color: themed("input-color");
      }
      line-height: 36px;
      margin-bottom: 8px;
      .icon_edit {
        font-size: 24px;
        color: #222222;
        @include themify($themes) {
          color: themed("input-color");
        }
        margin-left: 12px;
        cursor: pointer;
      }
    }
    .account-tel {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      @include themify($themes) {
        color: themed("hotel-color");
      }
      line-height: 20px;
    }
  }
  .tab-item {
    padding: 14px 24px 14px 24px !important;
  }
  .tab-info {
    cursor: auto;
    .name {
      color: #666666;
      @include themify($themes) {
        color: themed("set-t-color");
      }
      span {
        color: #999;
        padding-right: 10px;
      }
    }
    &:hover {
      background: #ffffff !important;
      @include themify($themes) {
        background: themed("search-bg") !important;
      }
    }
  }
  .bind {
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5977b3;
    line-height: 20px;
    margin-left: 8px;
  }
  // }
  .edit-user {
    padding: 40px 0 0 0;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    @include themify($themes) {
      background: themed("search-bg");
      box-shadow: 0px 0px 8px 0px themed("box-shadow");
    }
    position: absolute;
    right: 0;
    top: 0;
    margin: auto;
    z-index: 4;
    width: 100%;

    .edit-logo {
      width: 100px;
      height: 100px;
      position: relative;
      border-radius: 100%;
      margin: 0 auto 24px;
      cursor: pointer;
      .edit-img {
        width: 100%;
        height: 100%;
      }
      .edit-logo-mask {
        width: 100px;
        height: 100px;
        position: absolute;
        border-radius: 100%;
        top: 0;
        left: 0;
        background: rgba($color: #000000, $alpha: 0.5);
        .icon_add3 {
          color: #ffffff;
          font-size: 24px;
        }
      }
    }
    .banner-input {
      width: 350px;
      margin: auto;
      display: block;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      line-height: 33px;
      ::v-deep .el-input__inner {
        text-align: center;
        height: 48px;
        line-height: 48px;
        border-radius: 0;
        @include themify($themes) {
          background: themed("search-bg");
          border-color: themed("search-bg");
        }
      }
    }
    ::v-deep .banner-input.is-disabled .el-input__inner {
      @include themify($themes) {
        border-color: themed("search-his-bg");
        border-radius: themed("border-radius-min");
      }
    }
    .edit-foote {
      padding: 24px 0;
      .edit-btn {
        width: 80px;
        @include themify($themes) {
          border-radius: themed("border-radius-min");
        }
      }
    }
  }

  .editpas-pas {
    z-index: 4;
    width: 100%;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    @include themify($themes) {
      background: themed("search-bg");
      box-shadow: 0px 0px 8px 0px themed("box-shadow");
    }
    position: absolute;
    right: 0;
    bottom: 0;
    margin: auto;
    box-sizing: border-box;
    padding: 24px;
    .edit-pw-tit {

      line-height: 26px;
      position: relative;
      margin-bottom: 24px;
      .nav-item {
        margin-right: 24px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: #999999;
        cursor: pointer;
        &.active {
          color: #222222;
          font-weight: 600;
          @include themify($themes) {
            color: themed("input-color");
          }
        }
      }
      .icon_close {
        position: absolute;
        top: -4px;
        right: -4px;
        font-size: 24px;
        color: #999999;
        cursor: pointer;
        &:hover {
          color: #999999;
        }
      }
    }

    .banner-input {
      margin-bottom: 12px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 20px;
      ::v-deep .el-input__inner {
        // text-align: center;
        height: 36px;
        line-height: 36px;
        @include themify($themes) {
          border-radius: themed("border-radius-min");
        }
      }
    }
    .edit-foote {
      padding-top: 12px;
      .edit-btn {
        width: 80px;
        @include themify($themes) {
          border-radius: themed("border-radius-min");
        }
      }
    }

    .input-yzm {
      margin-bottom: 12px;
      .banner-input {
        width: 258px;
        margin-bottom: 0;
        ::v-deep .el-input__inner {
          border-radius: 0px;
          @include themify($themes) {
            border-radius: themed("border-radius-min") 0 0
              themed("border-radius-min");
          }
        }
      }
      .yzm-btn {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 20px;
        border: 1px solid #dcdfe6;
        border-left: 0px solid #dcdfe6;
        height: 36px;
        line-height: 36px;
        text-align: center;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;
        @include themify($themes) {
          border-color: themed("search-his-bg-h");
          color: themed("input-color");
          background: themed("search-bg");
          border-radius: 0 themed("border-radius-min")
            themed("border-radius-min") 0;
        }
        &:hover {
          background: #eeeeee;
          @include themify($themes) {
            background: themed("search-his-bg-h");
          }
        }
      }
    }
  }

  .edit-user-show {
    animation: editShow 0.3s;
    animation-fill-mode: forwards;
  }
  .edit-user-hide {
    animation: editHide 0.3s;
    animation-fill-mode: forwards;
  }
  .editpas-user-show {
    animation: PersonalTOP 0.3s;
    animation-fill-mode: forwards;
  }
  .editpas-user-hide {
    animation: PersonalTOPs 0.3s;
    animation-fill-mode: forwards;
  }
  .q-rcode {
    width: 320px;
    height: 312px;
    background: rgba(255, 255, 255, 1);
    @include themify($themes) {
      background: themed("price-bg");
    }
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    border-radius: 0;
    position: absolute;
    margin: auto;
    top: 0;
    right: 35px;
    bottom: 0;
    box-sizing: border-box;
    padding: 30px 55px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 9;
    .qrcode-content {
      width: 160px;
      height: 160px;
      border: 1px solid rgba(221, 221, 221, 1);
      margin-top: 20px;
      img {
        width: 100%;
      }
    }
    .qrcode-text {
      color: #777777;
      font-size: 14px;
      margin-bottom: 5px;
      width: 100%;
      text-align: left;
    }
  }

  @keyframes PersonalTOP {
    0% {
      transform: translateY(480px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  @keyframes PersonalTOPs {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(480px);
    }
  }
  @keyframes editShow {
    0% {
      transform: translateY(-400px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  @keyframes editHide {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(-400px);
    }
  }
}
</style>
<style lang="scss">
.xcx-menu-popover {
  width: 160px;
  .menu-item {
    padding: 0 8px;
    &:hover {
      background: #eeeeee;
      color: #222222;
      @include themify($themes) {
        background: themed("search-his-bg-h");
        color: themed("input-color");
      }
    }
    .icon_gou2 {
      font-size: 24px;
      vertical-align: middle;
      margin-right: 4px;
      opacity: 0;
    }
  }
  .show-item {
    .icon_gou2 {
      opacity: 1;
    }
  }
  .qr-code-box {
    width: 100%;
    height: 144px;
    padding: 0 8px;
    box-sizing: border-box;
    .el-image {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
