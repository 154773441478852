<!--
  权限变更的提示弹窗
-->
<template>
  <el-dialog
      :visible.sync="showAuthDig"
      :modal="true"
      :close-on-click-modal="false"
      :center="true"
      :append-to-body="true"
      custom-class="auth-change-dig"
      width="320px"
  >
    <div class="auth-tips-main">
      <i class="ask-icon iconfont icon_error"></i>
      <div class="info">
        <div class="title">当前账号操作权限有更新</div>
        <p class="message">页面相关操作可能有变化，请知晓</p>
      </div>
    </div>
    <div class="footer">
      <con-button title="知道了" :loading="btnLoading" @click="yes"></con-button>
    </div>
  </el-dialog>
</template>
<script>
import {mapState} from "vuex";
export default {
  data() {
    return {
      showAuthDig: false,
      btnLoading: false,
      isIframe: false,
    }
  },
  computed: {
    ...mapState({
      authChanged: state => state.authChanged, // 全是是否变更了
    }),
  },
  mounted() {
  },
  watch: {
    authChanged(val) {
      if (this.isIframe) return;
      this.showAuthDig = !!val;
    }
  },
  created() {
    this.isIframeHandler();
  },
  methods: {
    yes() {
      this.btnLoading = true;
      this.$axios({
        url: "NewWeddingApi/AuthorizeModule/Authorize/CompletePermissionChange?clientKey=X-Web-Permissions-Changed",
        method: "POST",
        success: (res) => {
          if (res && res.data && res.data.status) {
            this.$store.state.authChanged = false;
            window.location.reload();
          }
          this.btnLoading = false;
        },
        error(err) {
          this.btnLoading = false;
        },
      })
    },
    isIframeHandler() {
      this.isIframe = false;
      try {
        if (
            window.frameElement ||
            window.self!== window.top ||
            window !== window.parent
        ) {
          this.isIframe = true;
          console.log("当前页面在iframe中");
        }
      } catch (error) {
      }
    }
  },
}
</script>
<style scoped>
.el-dialog__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style scoped lang="scss">
.ask-icon {
  color: #222;
  transform: rotate(180deg);
  font-size: 24px;
}
::v-deep .auth-change-dig {
  margin: 0!important;
  background: #fff!important;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 24px;
  }
}
.auth-tips-main {
  display: flex;
  align-items: flex-start;
}
.info {
  margin-left: 10px;
}
.title {
  font-size: 20px;
  color: #222;
  font-weight: 600;
}
.message {
  margin-top: 6px;
  color: #999;
}
.footer {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  margin-top: 20px;
}
.footer ::v-deep .el-button {
  border-color: #222;
  background: #222;
  color: #fff;
  @include themify($themes) {
    border-radius: themed("border-radius-min");
  }
  &:hover {
    background: #333;
    color: #fff;
  }
}
</style>
<style>
.theme-Plus .auth-change-dig,
.theme-Dark .auth-change-dig {
  border-radius: 8px;
}
</style>
