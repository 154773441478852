var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accountSet-main"},[_c('header',{staticClass:"account-header-w flex flex-js"},[_c('div',{staticClass:"account-header"},[_c('i',{staticClass:"iconfont icon_arrow_left",on:{"click":_vm.closeAddDig}}),_c('span',[_vm._v("更多")])])]),_c('i',{staticClass:"head-line"}),_c('div',{staticClass:"tab-list"},[_vm._l((_vm.tabList2),function(item,index){return _c('div',{directives:[{name:"Auth",rawName:"v-Auth",value:({
        type: 'btn',
        code: item.name,
        pCode: 'Menu',
        rCode: '',
        isAdmin: _vm.isSetAuth,
      }),expression:"{\n        type: 'btn',\n        code: item.name,\n        pCode: 'Menu',\n        rCode: '',\n        isAdmin: isSetAuth,\n      }"}],key:'ind' + index,staticClass:"tab-item tab-item1 flex flex-b",on:{"click":function($event){return _vm.tabChange(item)}}},[_vm._v(" "+_vm._s(item.value)+" "),_c('i',{staticClass:"iconfont icon_arrow_right"})])}),(_vm.isSetAuth)?_c('div',{staticClass:"tab-item tab-item1 flex flex-b",on:{"click":function($event){return _vm.tabChange({ value: '权限设置' })}}},[_vm._v(" 权限设置 "),_c('i',{staticClass:"iconfont icon_arrow_right"})]):_vm._e(),_c('div',{staticClass:"tab-item tab-item1 flex flex-b",on:{"click":function($event){return _vm.$emit('openInquiryLog')}}},[_vm._v(" 询档记录 "),_c('i',{staticClass:"iconfont icon_arrow_right"})]),_c('div',{staticClass:"tab-item tab-item1 flex flex-b",on:{"click":function($event){return _vm.tabChange({value: '音乐库'})}}},[_vm._v(" 音乐库 "),_c('i',{staticClass:"iconfont icon_arrow_right"})]),_vm._l((_vm.tabList3),function(item,index){return _c('div',{directives:[{name:"Auth",rawName:"v-Auth",value:({
        type: 'btn',
        code: item.name,
        pCode: 'Menu',
        rCode: '',
        isAdmin: _vm.isSetAuth,
      }),expression:"{\n        type: 'btn',\n        code: item.name,\n        pCode: 'Menu',\n        rCode: '',\n        isAdmin: isSetAuth,\n      }"}],key:'i' + index,staticClass:"tab-item tab-item1 flex flex-b",on:{"click":function($event){return _vm.tabChange(item)}}},[_vm._v(" "+_vm._s(item.value)+" "),_c('i',{staticClass:"iconfont icon_arrow_right"})])})],2),_c('SetBranchInfo',{attrs:{"isShowSet":_vm.isShowSet},on:{"closeSetDig":function($event){_vm.isShowSet = false}}}),_c('CustomTitle',{attrs:{"isShowCustom":_vm.isShowCustom},on:{"closeCustomDig":function($event){_vm.isShowCustom = false}}}),_c('CustomLoginBg',{attrs:{"isShowCustom":_vm.isShowLoginBg},on:{"closeCustomDig":function($event){_vm.isShowLoginBg = false}}}),_c('FullscreenSet',{attrs:{"isShowSetFull":_vm.isShowSetFull},on:{"closeFull":function($event){_vm.isShowSetFull = false}}}),_c('SetSequence',{attrs:{"isShowSequence":_vm.isShowSequence},on:{"closeCustomDig":function($event){_vm.isShowSequence = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }