<template>
  <el-dialog
    title
    :modal="false"
    :visible.sync="showCKDig"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleClose"
    top="0"
    :show-close="true"
    :center="true"
    @opened="playVideo"
    custom-class="ckplayer-dialog"
  >
    <div class="ck-player">
      <xg-video-element
          :video-obj="videoObj" :key="videoObj.v_ID + videoObj.v_url"
          v-if="videoObj.v_ID"
      ></xg-video-element>
    </div>
  </el-dialog>
</template>

<script>
import xgVideoElement from "@/components/xg-video/xg-video-element.vue";

export default {
  name: "HelloWorld",
  props: ["showCKDig", "videoUrl"],
  data() {
    return {
      player: {},
      videoObj: {}
    };
  },
  mounted() {},
  components: {
    xgVideoElement
  },
  methods: {
    playVideo() {
      this.videoObj = {
        v_url: this.videoUrl,
        v_ID: '0',
        v_cover: '',
        v_screenshot: false,
        v_isTXY: false,
        autoplay: true,
      }
    },
    handleClose() {
      this.videoObj = {};
      this.$bus.$emit('xgVideoPlay', 'all');
      this.$emit("handleClose");
    },
  },
};
</script>

<style scoped>
.ck-player {
  width: 630px;
  height: 390px;
}
</style>
<style>
.ckplayer-dialog {
  width: 680px !important;
  height: 450px !important;
  background: #000000 !important;
  border-radius: 4px !important;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.34) !important;
}
.ckplayer-dialog .el-dialog__header {
  padding: 0px !important;
  height: 40px !important;
}
.ckplayer-dialog .el-dialog__body {
  padding: 0 25px !important;
  margin: auto !important;
  box-sizing: border-box !important;
}
</style>
