<template>
<div class="king-kong-case-display-version">
  <el-dialog
      top="0"
      width="480px"
      custom-class="conDiaLog king-kong"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="kingKongShowDig"
  >
    <div class="diaLog-body">
      <div class="header p16">
        <div class="closeBtn">
          <span class="title">已为您更新至最新版本</span>
<!--          <span class="go-old" @click="closeDiaLog('teamcase')">回到旧版</span>-->
        </div>
      </div>
      <div class="main-tips">
        1、支持经典版、简约版、深色版、深色plus四种前台展示风格 <br/>
        2、支持添加案例到资料夹打包分享 <br/>
        3、案例上传优化<br/>
        4、功能操作便利性优化<br/>
      </div>
      <p class="p-tips">赶快去试试吧~</p>
      <el-button class="btn next-btn" @click="closeDiaLog('case')">立即体验</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
export default {
  name: "king-kong-case-display-version",
  methods: {
    closeDiaLog(routeName) {
      this.$emit('closeDiaLog');
      // 回到旧版
      if (routeName === 'teamcase') {
        this.$store.state.UserShowSetInfo = {
          IsShowTip: false,
          ShowRemark: '1'
        }
        this.$forceUpdate();
        this.setCaseVersion(1).then(res => {
          if (res && res.data && res.data.status) {
            const urlObj = this.$getUrlPrefix(null,false);
            window.location = `${urlObj['1.0']}teamcase?v=${urlObj.version || '1.0'}`;
          }
        });
      } else {
        this.$store.state.UserShowSetInfo = {
          IsShowTip: false,
          ShowRemark: '2'
        }
        this.$forceUpdate();
        this.setCaseVersion(2);
      }
    },
    /**
     * 用户设置进入新版还是旧版
     * @param val
     */
    setCaseVersion(val) {
      return new Promise(resolve => {
        this.$axios({
          url: `NewWeddingApi/FileManage/FileManage2.0/FileNew/SetUserShowInfo`,
          method: "POST",
          data: {
            ShowRemark: val
          },
          success: (res) => {
            resolve(res);
            if (!res || !res.data || !res.data.status) {
              this.$Error(res.data.msg ? res.data.msg : "设置失败");
            }
          },
          error: () => {
            resolve(null);
            this.$Error("设置失败");
          },
        });
      })
    }
  },
  props: {
    kingKongShowDig: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style>
.king-kong-case-display-version .el-dialog__wrapper {
  z-index: 10000!important;
}
</style>
<style scoped lang="scss">
::v-deep .conDiaLog.king-kong .el-dialog__header {
  display: none;
}
::v-deep .conDiaLog.king-kong .el-dialog__body {
  padding: 0;
  background: #fff;
}
::v-deep .conDiaLog.king-kong .el-dialog__body .main {
  padding: 0 24px;
}
::v-deep .conDiaLog.king-kong .el-dialog__body {
  padding: 24px 24px 0 24px;
}
.header .closeBtn {
  display: flex;
  justify-content: space-between;
  .icon-btn {
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .icon-btn:hover {
    background: #EBEDF0;
    color: #666F80;
    border-radius: 50%;
  }
  .icon-btn i {
    font-size: 20px;
    color: #999;
  }
  span.title {
    font-size: 24px;
    font-weight: 600;
    color: #333333;
  }
}
.go-old {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
  line-height: 33px;
  padding: 0 6px;
  &:hover {
    background: #eee;
    color: #333;
  }
}
.main-tips {
  margin: 12px 0;
  padding: 12px;
  background: #EEEEEE;
  color: #222222;
  line-height: 24px;
}
.p-tips {
  color: #222222;
  line-height: 20px;
}
.next-btn {
  margin: 24px 0;
  border-radius: 0;
  background: #444444;
  border-color: #444444;
  color: #fff;
  &:hover {
    color: #fff;
    background: #222;
    border-color: #222222;
  }
}
</style>
