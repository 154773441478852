var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ConDig',{attrs:{"title":"作品颜色管理","width":"480px","isShowDig":_vm.showManageDig,"loading":_vm.isLodding,"appendToBody":true,"isShowFooter":false,"clickModal":false},on:{"open":_vm.initData,"closed":_vm.handleClose,"closeDig":_vm.handleClose,"cancelClick":_vm.handleClose}},[_c('main',{staticClass:"color-manage-box",attrs:{"slot":"dialogMain"},slot:"dialogMain"},[_c('el-button',{staticClass:"con-button add-btn",attrs:{"icon":"iconfont icon_add_big"},on:{"click":function($event){_vm.curColor = null;
        _vm.showAddColorDig = true;}}},[_vm._v("新增颜色")]),_c('draggable',{staticClass:"color-item-box small-scroll",attrs:{"forceFallback":true,"options":{
        animation: 500,
        ghostClass: 'sortable-ghost',
        chosenClass: 'sortable-chosen',
        handle: '.paixu-icon',
      },"draggable":".color-item"},on:{"change":_vm.change},model:{value:(_vm.colorList),callback:function ($$v) {_vm.colorList=$$v},expression:"colorList"}},_vm._l((_vm.colorList),function(color){return _c('div',{key:color.ID,staticClass:"color-item"},[_c('i',{staticClass:"color-icon",class:{ 'color-fff': color.ColorValue === '#FFFFFF' },style:({ background: color.ColorValue })}),_c('span',{staticClass:"item-name"},[_vm._v(_vm._s(color.ColorName ? color.ColorName.split(",")[0] : ""))]),_c('div',{staticClass:"btns-box"},[_c('ConIcon',{attrs:{"icon":"iconfont icon_edit"},on:{"click":function($event){_vm.curColor = color;
              _vm.showAddColorDig = true;}}}),_c('ConIcon',{attrs:{"icon":"iconfont icon_delete"},on:{"click":function($event){return _vm.showDelTip(color)}}}),_c('ConIcon',{staticClass:"paixu-icon",attrs:{"icon":"iconfont paixu"}})],1)])}),0),_c('ConTip',{attrs:{"type":"warn","isShowTip":_vm.showTipDig,"cancelText":"再想想","confirmText":"删除","confirmType":"err","tipText":_vm.tipText},on:{"confirmClick":function($event){return _vm.confirmDelColor()},"closeTipDig":function($event){_vm.showTipDig = false},"cancelClick":function($event){_vm.showTipDig = false}}}),_c('AddColor',{attrs:{"showAddColorDig":_vm.showAddColorDig,"curColor":_vm.curColor},on:{"handleClose":_vm.closeAddDig}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }