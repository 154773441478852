<!--
 * @Author: tangshuo
 * @Date: 2021-01-18 20:16:19
 * @LastEditors: tangshuo
 * @LastEditTime: 2022-03-09 23:15:23
 * @Remarks:
-->
<template>
  <main class="home" :class="{'Tablet': isTablet}">
    <nav class="home-menu min-w" id="homeMenu" :class="{ 'ishome-menu': isHome }">
      <div class="home-logo">
        <template v-if="$store.state.theme && $store.state.theme.indexOf('Min') == -1">
          <!--    多店选择      -->
          <div class="sel-branch-div" v-if="selBranchList.length > 1">
            <el-popover
                popper-class="nav-logo-name-popover"
                placement="bottom"
                :visible-arrow="false"
                trigger="click"
                v-model="showSelBranch">
              <div
                  class="select-div"
                  :class="{
                  'item-open': showSelBranch
                }"
                  slot="reference"
              >
                <span class="logo-span">{{ branchName }}</span>
                <i class="iconfont icon_arrow_down"></i>
              </div>
              <div class="option-div nav" v-if="showSelBranch">
                <div
                    class="option-item"
                    :class="{
                    'active': item.BranchName === branchName
                  }"
                    v-for="(item, index) in selBranchList"
                    :key="'d' + index"
                    @click="switchBranch(item)"
                >
                  {{item.BranchName}}
                  <i class="iconfont icon_gou2" v-if="item.BranchName === branchName"></i>
                </div>
              </div>
            </el-popover>
          </div>
          <span class="logo-span" v-else>{{ branchName }}</span>
        </template>
        <div
          class="home-img"
          v-else
          :style="{ backgroundImage: 'url(' + logoUrl + ')' }"
        ></div>
      </div>
      <div class="flex menu-list">
        <span
          class="menu-item"
          @click="menuChange(item)"
          v-Auth="{
            type: 'rmenu',
            code: item.name,
            pCode: '',
          }"
          :class="{ 'menu-item-active': item.name == menuActive }"
          v-for="(item, index) of menuList"
          :key="index"
        >
          <span>
            {{getAmountHead(item.name)}}
            <i class="new" v-if="IsShowDot && item.name.toLowerCase() === 'discover'"></i>
          </span>
        </span>
      </div>
      <div class="right-icon-box">
        <!--  全屏  -->
        <!--  safari全屏会影响 视频的全屏无法全屏 去掉safari的全屏  -->
        <ConIcon
            v-if="!isSafari()"
            class="qp-icon"
            @click="fullStytem()"
            :tipText="isFull ? '取消全屏' : '全屏'"
            :icon="isFull ? 'icon_suoxiao' : 'icon_fangda'"
        ></ConIcon>

        <!--   休息   -->
        <el-popover
            trigger="hover"
            class="menu-item"
            placement="bottom"
            :offset="30"
            popper-class="rest-popper"
            :key="homeData ? 'xq' + homeData.IsUsed + (homeData.DataList ? homeData.DataList.length : 0) : 'xq'"
            :disabled="resDisabled"
        >
          <ConIcon
              @click="themeChange()"
              slot="reference"
              class="qp-icon"
              tipText="休息"
              icon="icon_rest"
          ></ConIcon>
          <div class="rest-box q-rcode-sbid q-rcode-xxn" v-if="homeData && !homeData.IsUsed">
            <p class="title">开启形象页，提升公司形象展示</p>
            <p class="p-t">功能介绍：开启功能并设置好内容后，进入系统前将会进入公司门户页，有助于提升品牌形象。</p>
            <ConButton
                class="rest-btn"
                title="去开启"
                @click="isShowSetFull = true;"
            ></ConButton>
          </div>
          <div class="rest-box q-rcode-sbid q-rcode-xxn" v-if="homeData && homeData.IsUsed && homeData.DataList && homeData.DataList.length <= 0">
            <p class="title">形象页暂无内容，无法进入</p>
            <p class="p-t">您已开启【休息】功能但未上传展示内容，无法进入，请前往上传内容</p>
            <ConButton
                class="rest-btn"
                @click="isShowSetFull = true;"
                title="去设置"
            ></ConButton>
          </div>
        </el-popover>
        <!--        <ConIcon-->
        <!--          class="qp-icon"-->
        <!--          tipText="休息"-->
        <!--          @click="themeChange()"-->
        <!--          v-if="homeData && homeData.IsUsed && homeData.DataList.length"-->
        <!--          icon="icon_rest"-->
        <!--        ></ConIcon>-->


        <!--   换肤     -->
        <ConIcon
            ref="skinConIcon"
            class="qp-icon"
            @click="skinFun()"
            :tipText="'换肤'"
            icon="icon_huanfu"
        ></ConIcon>

<!--        <div @click.stop="skinFun" class="skin-div" @mouseenter="mouseenterImgUrl" @mouseleave="mouseleaveImgUrl">-->
<!--          <img class="skin" :src="skinImg" alt="">-->
<!--        </div>-->

        <!--    帮助    -->
        <ConIcon
          class="qp-icon"
          @click="openHelpDig(true)"
          tipText="帮助"
          icon="icon_help1"
        ></ConIcon>
      </div>

      <a
        class="head-sidebar"
        :class="{ 'show-sidebar': showSidebar }"
        href="javascript:;"
        @click="openSidebar()"
        ><i class="fa fa-bars"></i
      ></a>
    </nav>
    <!-- 右侧设置 -->
    <sidebar
      @tabSubmit="tabSubmit"
      :menuActive="menuActive"
      :showSidebar="showSidebar"
      @openHotelManage="openHotelManage"
      @openFriendManage="openFriendManage"
      @openShopping="openShopping"
      @openInquiryLog="openInquiryLog"
      @openAuthSetting="openAuthSetting"
      @openUserManage="openUserManage"
      @openWechatManage="openWechatManage"
      @openSetXcxMark="openSetXcxMark"
      @useSpaceDig="openUseSpaceDig"
      @openMusicLibrary="openMusicLibrary"
      @openHotelLabelManage="openHotelLabelManage"
      @closeSidebarDig="showSidebar = !showSidebar"
      @openStock="openStock"
      @vipRecord="vipRecord"
      @worksPaymentRecord="worksPaymentRecord"
      @openCustomCaseTemplateList="openCustomCaseTemplateList"
      @worksOrderRecord="worksOrderRecord"
    ></sidebar>

    <transition name="shoop-index"
      ><router-view
        @openCalendarDig="tocalendar"
        @openFolderDig="openFolderDig"
        :isShowNews="isShow"
        class="home-main"
		    :class="{'mask-info': isLock}"
        ref="homeBox"
      ></router-view
    ></transition>
    <div
      class="mask-main"
      v-if="isLock"
      @click="$bus.$emit('showRemind', 'Upgrade')"
    >
      <div class="lock-box">
        <i class="iconfont icon_suo"></i>
      </div>
      <span class="lock-text">立即解锁体验</span>
    </div>
<!--    <el-dialog-->
<!--      class="calendarbox"-->
<!--      v-if="iframeCalendar"-->
<!--      title="日历"-->
<!--      width="1200px"-->
<!--      :visible.sync="iframeCalendar"-->
<!--      v-loading="isiframeCalendarLoading"-->
<!--    >-->
<!--      <span slot="title" class="el-dropdown-link">-->
<!--        <div style="cursor: pointer" class="flex" @click="handlerCommand()">-->
<!--          {{ dropdownType == 1 ? "月视图" : "列表模式" }}-->
<!--          <i class="el-icon-arrow-downs"></i>-->
<!--        </div>-->
<!--      </span>-->
<!--      &lt;!&ndash; <iframe-->
<!--        width="100%"-->
<!--        class="iframe-calendar"-->
<!--        height="780px"-->
<!--        id="iframeCalendar"-->
<!--        :src="iframeCalendar"-->
<!--        v-if="iframeCalendar"-->
<!--      ></iframe> &ndash;&gt;-->
<!--      <Calendarboxs-->
<!--        v-if="iframeCalendar"-->
<!--        :iframeSrc="iframeCalendar"-->
<!--        @close="iframeCalendar = null"-->
<!--      ></Calendarboxs>-->
<!--    </el-dialog>-->

    <!--  日历  -->
    <div class="edit-case-iframe" v-if="iframeCalendar">
      <iframe :src="iframeCalendar"></iframe>
    </div>

    <div class="dgbox" v-show="isshowgd && dgVisible">
      <el-dialog
        :visible.sync="dgVisible"
        width="1080px"
        :modal="false"
        :close-on-press-escape="false"
      >
        <!-- <div> -->
        <iframe
          style="width: 100%; height: 768px"
          :src="src"
          scrolling="auto"
          frameborder="0"
          id="iframe"
        ></iframe>
        <!-- </div> -->
      </el-dialog>
    </div>
    <div
      class="edit-case-iframe 11"
      :class="{ 'shopp-iframe': isShopping }"
      v-if="isShowEditDig && !!editCaseUrl"
    >
      <iframe :src="editCaseUrl"></iframe>
    </div>
    <div class="edit-case-iframe" v-if="isShowRemindDig && !!remindDigUrl">
      <iframe :src="remindDigUrl"></iframe>
    </div>

    <!--  待完成任务  -->
    <div class="edit-case-iframe task" v-if="taskShow && !!taskUrl">
      <iframe :src="taskUrl"></iframe>
    </div>

    <AddFolder
      :showAddFolder="showAddFolder"
      @closeAddFolder="showAddFolder = false"
    ></AddFolder>

    <!--  换肤弹窗  -->
    <template>
      <el-drawer
          :visible.sync="isShowChangeSkin"
          direction="rtl"
          custom-class="sidebar-drawer"
          :append-to-body="false"
          :before-close="closeSkin"
      >
        <!-- 换肤 -->
        <transition name="friend-fade"
        ><changeSkin
            :isShowChangeSkin="isShowChangeSkin"
            v-if="true"
            @closeAddDig="closeSkin"
        ></changeSkin
        ></transition>
      </el-drawer>
    </template>

    <!-- 门户页 -->
    <FullscreenSet
        :isShowSetFull="isShowSetFull"
        @closeFull="isShowSetFull = false"
    ></FullscreenSet>

    <!--  金刚查看案例的版本模式  -->
    <king-kong-case-display-version :kingKongShowDig="showKingKongVersion" @closeDiaLog="closeKingKongCaseDig"></king-kong-case-display-version>

    <!--  权限变更  -->
    <auth-change-tip-dig></auth-change-tip-dig>
  </main>
</template>

<script>
import kingKongCaseDisplayVersion from "@/components/king-kong-case-display-version.vue";
import FullscreenSet from "@/views/components/sidebar/fullscreenSet.vue";
import changeSkin from "@/views/components/sidebar/changeSkin";
import sidebar from "./components/sidebar";
import AddFolder from "@/components/folder/addFolder";
import Calendarboxs from "@/components/calendarbox/calendarbox";
import {mapActions, mapState} from "vuex";
import {getBranchBaseInfo} from "@/api/base";
import {getCaseDisplayMode, setCaseDisplayMode} from "@/assets/js/utils/caseDiplayMode";
import {GetBaseSquareListIsShowDot} from "@/api/discover";
import authChangeTipDig from "@/components/auth-change-tip-dig.vue";
export default {
  name: "Home",
  components: {
    sidebar,
    AddFolder,
    Calendarboxs,
    changeSkin,
    FullscreenSet,
    kingKongCaseDisplayVersion,
    authChangeTipDig
  },
  data() {
    return {
      isTablet: sessionStorage.getItem('Tablet') === 'true', // 平板电脑
      // start 待完成任务
      taskShow: false,
      taskUrl: '',
      // end
      isShowSetFull: false,
      isShowChangeSkin: false,
      skinImg: 'images/home/icon_huanfu1.png', // 换肤
      isShowRemindDig: false,
      remindDigUrl: "",
      minTipNotify: null,
      showAddFolder: false, //是否显示添加到资料夹弹窗
      isFull: false, //是否全屏
      isShowEditDig: false,
      editCaseUrl: "",
      isshowgd: false,
      dgVisible: true,
      iframeCalendar: "",
      isHome: true,
      titleList: [],
      dropdownType: 1,
      src: `${process.env.VUE_APP_NEWSURL}message/index.html?IMUserId=${sessionStorage.getItem("IMUserId")}&v=${sessionStorage.getItem('x-web-version') || 0.4}`,
      cosIp: sessionStorage.getItem("BranchCosCdnUrl_B"),
      isiframeCalendarLoading: true, //日历loading
      logoUrl: "",
      branchName: "",
      isShow: false,
      showSidebar: false,
      hostAuthStr: sessionStorage.getItem("hostAuthStr"), //金刚系统所需请求头
      baseUserId: sessionStorage.getItem("baseUserId"),
      menuActive: "company",
      menuList: [],
      menuArr: [
        {
          value: "首页",
          isCanEdit: true,
          name: "company",
        },
        {
          value: "作品",
          isCanEdit: true,
          name: "case",
        },
        {
          value: "金刚",
          isCanEdit: true,
          name: "team",
        },
        {
          value: "酒店",
          isCanEdit: true,
          name: "hotel",
        },
        {
          value: "场地",
          isCanEdit: true,
          name: "banquet_hall",
        },
        {
          value: "物品",
          isCanEdit: true,
          name: "article",
        },
        {
          value: "灵感",
          name: "inspira",
          isCanEdit: true,
          isNewPage: false,
        },
        {
          value: "后台",
          name: "backManage",
          isNewPage: true,
        },
        {
          value: "发现",
          name: "discover",
          isCanEdit: true,
          isNewPage: false,
        },
      ],
      loginData: {},
      selBranchList: [],
      showSelBranch: false,
      showKingKongVersion: false,
      IsShowDot: false, // 发现是否显示新内容
    };
  },
  computed: {
    ...mapState({
      branchInfo: (state) => {
        return state.base.branchInfo;
      },
      homeData: (state) => state.homeData,
      headList: (state) => state.headList,
      helpMinData: (state) => state.helpMinData,
      isLock: (state) => state.isLock,
      authList: (state) => state.authList,
      isLoginToHome: state => state.isLoginToHome,
      theme: state => state.theme,
      userTask: state => state.userTask, // 待完成任务
    }),
    isShopping() {
      return this.editCaseUrl == `${process.env.VUE_APP_PAGEURL}Shopping`;
    },
    resDisabled() {
      return this.homeData && this.homeData.IsUsed && this.homeData.DataList && this.homeData.DataList.length > 0
    },
  },
  watch: {
    $route() {
      let activeIndex = this.$route.path.split("/")[1];
      this.menuActive = activeIndex;
      this.getMenuActive();
    },
    theme(val) {
      if (val === 'Classic') return;
      this.getBranchSelect(); // 获取多店信息
    },
  //   isShowRemindDig: {
  //     handler(val){
  //       window.addEventListener("message", event => {
  //         if(event.data){
  //           let data = {};
  //           if (typeof event.data == 'string') {
  //             try {
  //               data = JSON.parse(event.data);
  //             } catch(e) {
  //               data = {};
  //             }
  //           } else {
  //             data = event.data;
  //           }
  //
  //           if (event.origin == window.origin && data.isSwitchRemid){
  //             this.switchBranch(data)
  //           }
  //         }
  //       })
  //     }
  //   }
  },
  created() {
    const isShowCaseVersionDig = this.getShowKingKongVersion(); // 获取金刚 案例展示版本 、新版 /case 旧版 /teamcase 是否弹出新版提示框
    // 不弹 金刚案例版本提提示才弹 任务
    if (!isShowCaseVersionDig) {
      this.showTask();
    }
    // window.parenttest = () => (this.isiframeCalendarLoading = false); // 日历

    this.isShow = this.$store.state.userPhone != sessionStorage.getItem("InstallAccount_B");
    let _this = this;

    this.getFolderTotal(); // 获取资料夹数量
    // this.getHostHead().then(() => {
    //   this.getFolderApi(this.hostAuthStr);
    // });
  },
  mounted() {
    let _this = this;
    this.getMenuActive();
    if (!this.$store.state.useSpaceInfo.TotalPeople) {
      this.getBranchFileSize();
    }
    this.$bus.$on("chatWindow", this.chatWindow);
    this.$bus.$on("customChange", () => {
      this.getHeader(false);
    });
    this.$bus.$on("openAddFolder", () => {
      _this.showAddFolder = true;
    });
    //显示续费、升级、到期提醒等弹窗
    this.$bus.$on("showRemind", (type = "Tip") => {
      _this.openRemindDig(type);
    });
    this.$bus.$on("baseInfoChange", () => {
      getBranchBaseInfo().then((res) => {
        if (res.status) {
          if (res.data) {
            sessionStorage.setItem("CityName", res.data.CityName || "");
          }
          _this.logoUrl = _this.cosIp + res.data.BranchLogoUrl;
          _this.branchName = res.data.BranchName;
        }
      });
    });
    // this.dgVisible = false;
    // setTimeout(() => {
    //   this.isshowgd = true;
    // }, 1000);

    this.getHeader(!this.isLoginToHome); // 不是登录页跳转进来的才刷新导航栏接口

    this.$store.commit("setMesNum", 0);
    // window.addEventListener("message", (event) => {
    //   // 根据上面制定的结构来解析iframe内部发回来的数据
    //   const data = event.data;
    //   // console.log('未读消息数前台接收=========='+data)
    //   this.$store.commit("setMesNum", data.cmd);
    // });
    getBranchBaseInfo().then((res) => {
      if (res.status) {
        if (res.data) {
          sessionStorage.setItem("CityName", res.data.CityName || "");
        }
        this.logoUrl = this.cosIp + res.data.BranchLogoUrl;
        this.branchName = res.data.BranchName;
      }
    });

    // 获取多店信息
    this.getBranchSelect();

    this.getCaseShowWay(); // 案例封面图展示方式

    this.$bus.$on('closeFindShowDot', () => {
      this.IsShowDot = false;
      sessionStorage.setItem('IsFindShowDot',this.IsShowDot);
    });

    this.$bus.$on("openHelpDig1", () => {
      this.openHelpDig();
    });
    this.$bus.$on('resize',this.resize);
    this.$bus.$on('receiveMessage',this.receiveMessage);
  },
  beforeDestroy() {
    this.$bus.$off('resize',this.resize);
    this.$bus.$off('receiveMessage',this.receiveMessage);
    const _this = this;

    // 注销事件
    this.$bus.$off("openHelpDig1", () => {
      this.openHelpDig();
    });
    this.$bus.$off("chatWindow", this.chatWindow);
    this.$bus.$off("customChange",() => {
      this.getHeader(false);
    });
    this.$bus.$off("openAddFolder", () => {
      this.showAddFolder = true;
    });
    //显示续费、升级、到期提醒等弹窗
    this.$bus.$off("showRemind", (type = "Tip") => {
      this.openRemindDig(type);
    });
    this.$bus.$off("baseInfoChange", () => {
      getBranchBaseInfo().then((res) => {
        if (res.status) {
          if (res.data) {
            sessionStorage.setItem("CityName", res.data.CityName || "");
          }
          _this.logoUrl = _this.cosIp + res.data.BranchLogoUrl;
          _this.branchName = res.data.BranchName;
        }
      });
    });
    this.$bus.$off('closeFindShowDot', () => {
      this.IsShowDot = false;
      sessionStorage.setItem('IsFindShowDot',this.IsShowDot);
    });
  },
  methods: {
    ...mapActions({
      getFolderApi: "getFolderApi",
      getFolderCount: 'getFolderCount',
    }),
    getFindNewContent() { // 获取发现是否有新内容
      const IsShowDot = sessionStorage.getItem('IsFindShowDot')
      if (
          IsShowDot !== 'true' &&
          this.menuList &&
          this.menuList.find(c => c.name.toLowerCase() === 'discover')
      ) {
        GetBaseSquareListIsShowDot().then(res => {
          if (res && res.status) {
            this.IsShowDot = res.data.IsShowDot || false;
            sessionStorage.setItem('IsFindShowDot',this.IsShowDot);
          }
        })
      }
    },
    /**
     * 关闭 金刚案例版本弹窗 再判断是否要谈任务弹窗
     */
    closeKingKongCaseDig() {
      this.showKingKongVersion = false;
      this.showTask();
    },
    /**
     * 获取金刚 案例展示版本 、新版 /case 旧版 /teamcase
     * IsShowTip  是否弹出新版提示框  false不需要 true需要
     */
    getShowKingKongVersion() {
      const showKingKongCaseDisplayVersionNum = sessionStorage.getItem('showKingKongCaseDisplayVersionNum'); // 弹的次数
      const  branchType = sessionStorage.getItem("BranchType_B");

      // 只能弹一次 并且只有金刚账号才弹
      if (
          (
              showKingKongCaseDisplayVersionNum &&
              showKingKongCaseDisplayVersionNum == '1'
          ) ||
          branchType === '0'
      ) {
        return false;
      }

      const userShowSetInfo = this.$store.state.UserShowSetInfo;
      this.showKingKongVersion = userShowSetInfo && userShowSetInfo.IsShowTip ? userShowSetInfo.IsShowTip : false;
      this.showKingKongVersion ? sessionStorage.setItem('showKingKongCaseDisplayVersionNum','1') : null; // 金刚显示版本次数
      return this.showKingKongVersion;
    },
    /**
     * 获取资料夹数量
     */
    async getFolderTotal() {
      if (!sessionStorage.getItem('hostAuthStr')) {
        try {
          await this.getHostHead();
        } catch (error) {
          console.log(error, 'getHostHead');
        }
      }
      this.getFolderCount(this.hostAuthStr).then(r => {});
    },
    /**
     * 判断是否是safari
     * @returns {boolean}
     */
    isSafari() {
      return !/Chrome/.test(navigator.userAgent);
    },
    closeSkin() {
      this.isShowChangeSkin = false;
      setTimeout(() => {
        if (
            this.$refs.skinConIcon &&
            this.$refs.skinConIcon.$refs &&
            this.$refs.skinConIcon.$refs.tooltip
        ) {
          this.$refs.skinConIcon.$refs.tooltip.handleBlur();
        }
      })
    },
    /**
     * 获取案例封面图展示方式
     */
    getCaseShowWay() {
      this.$axios({
        url: "NewWeddingApi/FileManage/LabelManage/BranchLabel/GetBranchFileShowStyle",
        method: "POST",
        data: {
          Key: 'caseCoverShowWay',
        },
        success(res) {
          if (res && res.data && res.data.status) {
            if (
                res.data.data === '' ||
                res.data.data === 'cross'
            ) {
              this.$store.state.caseCoverShowWay = 'cross';
            } else if (
                res.data.data === 'vertical'
            ) {
              this.$store.state.caseCoverShowWay = res.data.data;
            }
          }
        },
        error(err) {
          console.log(err);
          this.$Error("获取失败");
        },
      })
    },
    /**
     * 是否有任务待完成
     */
    showTask() {
      if (this.$route.name === 'inspira' && !this.$store.state.isLock) return; // 进入灵感路由不弹，跳进灵感项目页里再弹
      this.taskShow =
          this.userTask &&
          this.userTask.data &&
          this.userTask.data.IsShow &&
          !this.userTask.taskDisplayed &&
          this.userTask.unfinished
      ;
      const urlObj = this.$getUrlPrefix();
      this.taskUrl = `${urlObj['1.0']}taskDig?v=${urlObj.version || '1.0'}`;
    },
    /**
     *  暗黑版 获取店铺切换列表
    */
    getBranchSelect() {
      if (this.$store.state.theme === 'Min') return;

      // 店铺Id 大于一个 才获取多店信息
      let strid = sessionStorage.getItem("UserBranchIds") || "";
      let branchIds = strid.split(":");
      if (branchIds.length <= 1) return;

      this.$axios({
        method: "GET",
        url: "NewWeddingApi/Organization/BranchRelation/GetBranchSelect",
        data: { strid: sessionStorage.getItem("UserBranchIds") },
        success(res) {
          if (res.data.status) {
            this.selBranchList = res.data.data || [];
          }
        },
        error(err) {
          console.log(err);
        },
      });
    },
    // 换肤
    skinFun() {
      this.isShowChangeSkin = true;
    },
    // mouseenterImgUrl() {
    //   const theme = this.$store.state.theme;
    //   // 简约版
    //   if (theme === 'Min') {
    //     this.skinImg = 'images/home/icon_huanfu3.png'
    //   } else {
    //     // 深色版
    //     this.skinImg = 'images/home/icon_huanfu2.png'
    //   }
    // },
    // mouseleaveImgUrl() {
    //   // 简约版
    //   this.skinImg = 'images/home/icon_huanfu1.png'
    // },
    /**
     * 打开音乐库
     */
    openMusicLibrary() {
      const urlObj = this.$getUrlPrefix();
      this.editCaseUrl = `${urlObj['1.0']}musicLibrary?v=${urlObj.version || '1.0'}`;
      this.isShowEditDig = true;
    },
    worksOrderRecord() {
      const urlObj = this.$getUrlPrefix();
      let path = urlObj['1.0'];
      if (process.env.NODE_ENV === 'development') {
        path = 'http://192.168.0.24:1024/'
      }
      this.editCaseUrl = `${path}worksOrderRecord?v=${urlObj.version || '1.0'}`;
      this.isShowEditDig = true;
    },
    // 自定义作品模板管理
    openCustomCaseTemplateList() {
      const urlObj = this.$getUrlPrefix();
      let path = urlObj['1.0'];
      if (process.env.NODE_ENV === 'development') {
        path = 'http://192.168.0.24:1024/'
      }
      this.editCaseUrl = `${path}customCaseTemplateList?v=${urlObj.version || '1.0'}`;
      this.isShowEditDig = true;
    },
    /**
     * 打开库存管理
     */
    openStock() {
      const urlObj = this.$getUrlPrefix();
      let path = urlObj['1.0'];
      if (process.env.NODE_ENV === 'development') {
        path = 'http://192.168.0.24:1024/'
      }
      this.editCaseUrl = `${path}showStockMessage?v=${urlObj.version || '1.0'}`;
      this.isShowEditDig = true;
    },
    /**
     * 打开标签管理
     */
    openHotelLabelManage() {
      const urlObj = this.$getUrlPrefix();
      this.editCaseUrl = `${urlObj['1.0']}hotelLabelManage?v=${urlObj.version || '1.0'}`;
      this.isShowEditDig = true;
    },
    /**
     * 打开续费、升级、到期提醒等弹窗
     */
    openRemindDig(type = "Tip") {
      //Tip:提示 Purchase:购买容量, Renew:续费, Upgrade:升级
      this.remindDigUrl = `${this.$getUrlPrefix('backstage')}#/Remind?showDigType=${type}`;
      this.isShowRemindDig = true;
    },
    themeChange() {
      // 没有设置门户页 或者没开启门户页去开启
      if (
          !this.homeData ||
          (
              this.homeData &&
              (
                  !this.homeData.IsUsed ||
                  this.homeData.DataList.length <= 0
              )
          )
      ) {
        this.isShowSetFull = true;
        return;
      }

      const urlObj = this.$getUrlPrefix();
      this.editCaseUrl = `${urlObj['1.0']}YWHome?v=${urlObj.version || '1.0'}`;
      if (this.isHome) {
        //暂停视频播放
        this.$bus.$emit("mutePlay");
      }
      this.isShowEditDig = true;
    },
    /**
     * 获取审核申请所需请求头
     */
    getHostHead() {
      return new Promise((resolve) => {
        if (sessionStorage.getItem("baseUserId")) {
          this.baseUserId = sessionStorage.getItem("baseUserId");
          resolve("");
          return;
        }
        this.$axios({
          url:
            process.env.VUE_APP_TEAMURL +
            "xlapi/HostManage/HostUserManage/HostUserLogin/GetHostUserIdentity",
          method: "POST",
          data: {
            BranchId: sessionStorage.getItem("BranchId"),
            BranchSource: "lh",
            userid: sessionStorage.getItem("UserId"),
          },
          success(res) {
            if (res.data.AuthorizationStr) {
              let hostAuthStr = res.data.AuthorizationStr;
              this.hostAuthStr = res.data.AuthorizationStr;
              this.baseUserId = hostAuthStr.split("|")[0];
              sessionStorage.setItem("hostAuthStr", hostAuthStr);
              sessionStorage.setItem("baseUserId", this.baseUserId);
              resolve(res.data.AuthorizationStr);
            } else {
              // console.log("获取金刚所需请求头失败");
            }
          },
          error() {
            // console.log("获取金刚所需请求头失败");
          },
        });
      });
    },
    /**
     * 获取屏保图片
     */
    getScreenProtectionInfo() {
      this.$axios({
        url: "NewWeddingApi/FileManage/FileManage2.0/FileNew/GetBranchScreenProtectionInfo",
        method: "POST",
        success(res) {
          if (res && res.data.status) {
            this.$store.state.homeData = this.$Clone(res.data.data);
            this.$forceUpdate();
          } else {
            this.$Error(res.data.msg ? res.data.msg : "获取失败");
          }
        },
        error(err) {
          this.$Error("获取失败");
          this.isLoading = false;
        },
      });
    },
    /**
     * 接收消息 关闭弹窗
     */
    receiveMessage(e) {
      // if (process.env.NODE_ENV === 'development') console.log(e,'home');
      if (e.origin === window.origin || process.env.NODE_ENV === 'development') {
        //保证是同源消息
        let data = e.data;
        if (data && data === 'close') {
          this.isShowEditDig = false;
        }
        if (data && data === 'checkOut') { // 退出登录
          sessionStorage.setItem('isTipLogin', 1)
          setTimeout(() => {
            window.location = process.env.VUE_APP_PAGEURL;
          }, 1500);
          this.$Error("登录失效");
        }

        // 关闭消息弹窗
        if (data && data === 'closeImMessage') {
          this.dgVisible = false;
        }
          // 关闭日历弹窗
        if (data && data === 'closeCalender') {
          this.iframeCalendar = null;
        }

        // 关闭待完成任务
        if (data && data === 'closeTaskDig') {
          this.taskShow = false;
          this.$store.state.userTask.taskDisplayed = true;
        } else if (data && data === 'refreshRestData') {
          // 屏保页数据改变 需要刷新休息数据
          const storeStorage = sessionStorage.getItem('insercell') ? JSON.parse(sessionStorage.getItem('insercell')) : null;
          if (storeStorage && storeStorage.homeData) {
            this.$store.state.homeData = storeStorage.homeData;
            this.$forceUpdate();
          } else {
            this.getScreenProtectionInfo();
          }
        } else if (data && data == "refreshVipRecord") { // 刷新购买作品记录 数量、开通会员记录 数量
          this.isShowEditDig = false;
          this.$bus.$emit('refreshMyShopVipFileCountInfo')
        } else if (data && data == "closeEditCase") {
          this.isShowEditDig = false;
        } else if (data && data === "clearData") {
          // console.log(data);
          //清空helpMinData
          this.$store.state.helpMinData = {};
        } else if (data && data instanceof Object && data.key) {
          if (data && data instanceof Object && data.key === "openMinTip") {
            if (this.minTipNotify) {
              this.minTipNotify.close();
            }
            let _this = this;
            this.$store.state.helpMinData.FileTitle = data.FileTitle;
            this.$store.state.helpMinData.FileId = data.FileId;
            this.minTipNotify = this.$notify({
              title: "",
              message: "帮助教程：" + data.FileTitle,
              position: "bottom-right",
              iconClass: "iconfont icon_help",
              customClass: "min-notification",
              onClick() {
                // console.log("重新激活弹窗");
                //重新激活
                _this.openHelpDig();
                if (_this.minTipNotify) {
                  _this.minTipNotify.close();
                }
              },
              onClose() {
                _this.$store.state.helpMinData = {};
              },
              duration: 0,
            });
            return;
          }
          if (data && data instanceof Object && data.key === "closeRemind") {
            this.isShowRemindDig = false;
            return;
          }
          if (data && data instanceof Object && data.key === "RefreshRemind") {
            //刷新数据
            let showDigType = data.showDigType || "Tip";
            if (showDigType == "Upgrade") {
              //重新获取权限 从init进入
              this.$store.state.authButton = {};
              this.$router.push({
                path: "/init",
                query: {
                  userPhone: this.$store.state.userPhone,
                },
              });
            } else {
              // console.log("刷新空间");
              this.$bus.$emit("refreshRemind");
              this.getBranchFileSize(true);
            }
            return;
          }
          this.isShowEditDig = false;
          let DSkin = this.$store.state.theme;
          sessionStorage.setItem("versions", DSkin == "Classic" ? "1.0" : "2.0");
          sessionStorage.setItem("insercell", JSON.stringify(this.$store.state));
          let jwtoken = sessionStorage.getItem("jwtStr");
          if (data.key === "Back") {
            let locations = `${this.$getUrlPrefix('backstage',false)}`;
            window.location = locations;
          } else if (data.key === "Inspira") {
            this.$router.push('/inspira');
          } else {
            if (DSkin !== "Classic") {
              //进入简约版
              sessionStorage.setItem(
                  "insercell",
                  JSON.stringify(this.$store.state)
              );
              window.location.href = `${this.$getUrlPrefix('2.0',false)}#${data.key}`;
              return;
            }
            this.$router.push(data.key);
          }
        } else if (
            data &&
            data instanceof Object &&
            data.hasOwnProperty('cmd')
        ) {
          // console.log('未读消息数前台接收=========='+data)
          this.$store.commit('setMesNum', data.cmd);
        } else {
          if (
              data &&
              typeof data == 'string' &&
              data.indexOf('{') !== -1 &&
              data.indexOf('}') !== -1
          ) {
            try {
              data = JSON.parse(event.data);
            } catch (e) {
              data = {};
            }

            if (data && data.isSwitchRemid) {
              this.switchBranch(data)
            }
          }
        }
      }
    },
    resize() {
      const docFull =
          document.fullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitFullscreenElement ||
          document.msFullscreenElement;
      this.isFull = window.document.body.clientHeight === window.screen.height || !!docFull;
      return this.isFull;
    },
    /**
     * 获取店铺文件大小
     */
    getBranchFileSize(isSize=false) {
      this.$axios({
        method: "POST",
        url: "NewWeddingApi/FileManage/FileManage2.0/FileNew/GetBranchPathStaticsInfo",
        success(res) {
          if (res.data.status) {
            this.$store.state.useSpaceInfo = res.data.data;
            let val = res.data.data.ExDay;
            if ((val < 0 || (val >= 0 && val <= 7))&&!isSize) {
              this.openRemindDig("Tip");
            }
          }
        },
        error(err) {
          // console.log(err);
        },
      });
    },
    /**
     * 全屏显示
     */
    fullStytem() {
      let element = document.documentElement;
      if (this.resize()) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
    },
    /**
     * 获取自定义表头
     */
    getAmountHead(key) {
      let head = this.titleList.find((h) => h.Key === key);

      if (head && head.Name) {
        return head.Name;
      }
      switch (key) {
        case "company": {
          return "公司";
        }
        case "case": {
          return "案例";
        }
        case "hotel": {
          return "酒店";
        }
        case "team": {
          return "金刚/资源";
        }
        case "inspira": {
          return "灵感";
        }
        case "backManage": {
          return "后台";
        }
        case "banquet_hall": {
          return "场地";
        }
        case "article": {
          return "物品";
        }
        default:
          break;
      }
    },
    /**
     * 获取自定义标题
     * isForced 是否强制更新
     */
    getHeader(isForced = true) {
      if (!isForced && this.headList && this.headList.length > 0) {
        this.titleList = this.$Clone(this.headList);
        this.getTitleNav();
        return;
      }
      this.$axios({
        method: "post",
        url: "/NewWeddingApi/OrderManage/HeaderManage/HeaderDetail/GetHeader",
        data: {
          Type: 5,
        },
        success: (res) => {
          if (res.data.status) {
            let titleList = res.data.data;
            let authList = this.authList || [];
            titleList.forEach((title) => {
              title.isEdit = false;
              title.oldName = title.Name;
              title.isAuth = !!authList.find(
                  (a) => a.value.toLowerCase() === title.Key.toLowerCase()
              );
            });
            this.titleList = titleList.filter((t) => t.isAuth && t.IsShow);
            this.$store.state.headList = this.$Clone(this.titleList);
            this.getTitleNav();
          } else {
            this.$Error(res.data.msg ? res.data.msg : "获取失败");
          }
        },
        error(err) {
          this.$Error("获取失败");
          // console.log(err);
        },
      });
    },
    /**
     * 暂时注释掉显示与隐藏导航栏的操作
     */
    getTitleNav() {
      // 后台
      let backMessage = {
          value: "后台",
          name: "backManage",
          isNewPage: true,
      };
      let back = this.authList.find((a) => a.value === "BackManage");

      let list = [];
      this.titleList.forEach(item => {
        this.menuArr.forEach(c => {
          if (c.name.toLowerCase() === item.Key.toLowerCase()) {
            list.push(c);
          }
        })
      })
      this.menuList = back ? [...list, backMessage] : [...list];

      let navAllList = this.$store.state.navAllList; // 除后台之外的导航栏 '/case,/company,/hotel,/team,/banquet_hall,/article,/inspira,/teamcase'
      let navAll_divide_inspira = navAllList.filter(c => c !== '/inspira');

      this.getFindNewContent(); // 获取发现是否有新内容
      // 当前路由为 noNavIndex ，更改导航栏的显示后
      if (
          this.$route.name === 'noNavIndex' &&
          this.titleList.find(c => navAll_divide_inspira.map(l => l.toLowerCase() === c.Key.toLowerCase())) &&
          list.length > 0
      ) {
        if (list.length > 1 && list[0].name === 'inspira') {
          this.$router.push({
            name: list[1].name
          });
          return;
        }

        if (list[0].name !== 'inspira') {
          this.$router.push({
            name: list[0].name
          });
          return;
        }
      }

      // 除后台之外的 导航栏 为空的情况 ， 当前的路由又是 所有导航栏navAllStr里包含的情况
      if (
          list.length <= 0 &&
          navAllList.find(c => c.toLowerCase() === this.$route.path.toLowerCase())
      ) {
        this.$router.push({ name: 'noNavIndex'});
        return;
      }

      // 除后台之外的 导航栏 不为空的情况，。。。。。。
      if (
          list.length > 0 &&
          navAllList.find(c => c.toLowerCase() === this.$route.path.toLowerCase()) &&
          !list.find(item => this.$route.name === item.name)
      ) {
        if (this.$route.path.toLowerCase() !== '/teamcase') {
          if (list.length === 1 && list[0].name === 'inspira') {
            this.$router.push({ name: 'noNavIndex'});
            return;
          }

          if (list.length > 1 && list[0].name === 'inspira') {
            this.$router.push({
              name: list[1].name
            });
            return;
          }

          this.$router.push({
            name: list[0].name
          });
        } else {
          if (!list.find(n => n.name.toLowerCase() === 'case'))  {
            this.$router.push({
              path: '/noNavIndex'
            });
          }
        }
      }
    },
    chatWindow() {
      this.isshowgd = true;
      this.dgVisible = true;
    },
    /**
     * 打开帮助
     */
    openHelpDig(isInit = false) {
      if (isInit) {
        this.helpMinData.FileId = "";
        this.helpMinData.FileTitle = "";
      }
      let fileId =
        this.helpMinData && this.helpMinData.FileId
          ? this.helpMinData.FileId
          : "";
      let fileTitle =
        this.helpMinData && this.helpMinData.FileTitle
          ? this.helpMinData.FileTitle
          : "";
      this.editCaseUrl = `${this.$getUrlPrefix('backstage')}#/Help?fileId=${fileId}&fileTitle=${fileTitle}`;
      this.isShowEditDig = true;
      if (this.minTipNotify) {
        this.minTipNotify.close();
      }
    },
    /**
     * 打开资料夹弹窗
     */
    openFolderDig() {
      sessionStorage.setItem('insercell', JSON.stringify(this.$store.state))
      const urlObj = this.$getUrlPrefix();
      let path = urlObj['1.0'];
      if (process.env.NODE_ENV === 'development') path = 'http://192.168.0.2:1024/';
      this.editCaseUrl = `${path}folderDig?v=${urlObj.version || '1.0'}`;
      this.isShowEditDig = true;
    },
    // 开通的会员
    vipRecord() {
      const urlObj = this.$getUrlPrefix();
      this.editCaseUrl = `${urlObj['1.0']}vipRecords?v=${urlObj.version || '1.0'}`;
      this.isShowEditDig = true;
    },
    // 购买的作品
    worksPaymentRecord() {
      const urlObj = this.$getUrlPrefix();
      this.editCaseUrl = `${urlObj['1.0']}worksPaymentRecord?v=${urlObj.version || '1.0'}`;
      this.isShowEditDig = true;
    },
    /**
     * 好友管理
     */
    openFriendManage() {
      const urlObj = this.$getUrlPrefix();
      this.editCaseUrl = `${urlObj['1.0']}friendManage?v=${urlObj.version || '1.0'}`;
      this.isShowEditDig = true;
    },
    /**
     * 打开小程序专享版设置
     */
    openWechatManage() {
      const urlObj = this.$getUrlPrefix();
      this.editCaseUrl = `${urlObj['1.0']}wechatManage?v=${urlObj.version || '1.0'}`;
      this.isShowEditDig = true;
    },
    /**
     * 打开设置小程序水印
     */
    openSetXcxMark() {
      const urlObj = this.$getUrlPrefix();
      this.editCaseUrl = `${urlObj['1.0']}setwaterMark?isEditDefault=true&v=${urlObj.version || '1.0'}`;
      this.isShowEditDig = true;
    },
    /**
     * 打开文件明细
     */
    openUseSpaceDig() {
      const urlObj = this.$getUrlPrefix();
      this.editCaseUrl = `${urlObj['1.0']}useSpaceDig?v=${urlObj.version || '1.0'}`;
      this.isShowEditDig = true;
    },
    /**
     * 用户管理
     */
    openUserManage() {
      const urlObj = this.$getUrlPrefix();
      this.editCaseUrl = `${urlObj['1.0']}userManage?v=${urlObj.version || '1.0'}`;
      this.isShowEditDig = true;
    },
    /**
     * 权限设置管理
     */
    openAuthSetting() {
      const urlObj = this.$getUrlPrefix();
      this.editCaseUrl = `${urlObj['1.0']}authSetting?v=${urlObj.version || '1.0'}`;
      this.isShowEditDig = true;
    },
    /**
     * 酒店管理
     */
    openHotelManage() {
      const urlObj = this.$getUrlPrefix();
      this.editCaseUrl = `${urlObj['1.0']}hotelManage?v=${urlObj.version || '1.0'}`;
      this.isShowEditDig = true;
    },
    /**
     * 询档记录
     */
    openInquiryLog() {
      const urlObj = this.$getUrlPrefix();
      this.editCaseUrl = `${urlObj['1.0']}inquiryLog?v=${urlObj.version || '1.0'}`;
      this.isShowEditDig = true;
    },
    /**
     * 打开商城
     */
    openShopping() {
      const urlObj = this.$getUrlPrefix();
      this.editCaseUrl = `${urlObj['1.0']}Shopping?v=${urlObj.version || '1.0'}`;
      this.isShowEditDig = true;
    },
    // handlerCommand() {
    //   try {
    //     this.dropdownType = this.dropdownType === 1 ? 0 : 1;
    //     document
    //       .getElementById("iframeCalendar")
    //       .contentWindow.handlerDefultApi(this.dropdownType);
    //     this.$forceUpdate();
    //   } catch (e) {
    //     // console.log(e);
    //   }
    // },
    //跳转日历
    tocalendar() {
      this.isiframeCalendarLoading = true;
      this.iframeCalendar = `${process.env.VUE_APP_NEWSURL}calender/index.html?time=${new Date().getTime()}`;
    },
    getMenuActive(val) {
      this.menuActive = val ? val : this.$route.path.split("/")[1];
      this.isHome = this.menuActive == "company";
    },
    tabSubmit(val) {
      this.getMenuActive(val);
      this.openSidebar();
    },
    menuChange(val) {
      if (this.menuActive === val.name) {
        // 暗黑版本 当前也自定义案例详情页时 关闭自定义案例详情弹窗
        if (
            this.menuActive === 'case' &&
            (
                this.$store.state.theme === 'Dark' ||
                this.$store.state.theme === 'Plus'
            )
        ) this.$bus.$emit('closeCustormCaseDetail2');

        return;
      }
      this.isHome = val.name == "company";
      if (val.name && !val.isNewPage) {
        this.$router.push({ name: val.name });
        this.menuActive = val.name;
      } else if (val.name === "backManage") {
        this.$axios({
            method: "post",
            url: "NewWeddingApi/UserLogin/LoginMethod/UserLogin/IsNeedChange",
            data: {
              userid: sessionStorage.getItem("UserId"),
              frometype: 0
            },
            success(res) {
              if(res.data){
                let authInfo = res.data;
                //储存请求头信息
                let headStr = authInfo.Authorization;
                sessionStorage.setItem("Authorization", headStr);
                localStorage.setItem("Authorization", headStr);

                sessionStorage.setItem("hostAuthStr", authInfo.AuthorizationStr);
                localStorage.setItem("hostAuthStr", authInfo.AuthorizationStr);

                if (authInfo.AuthorizationStr) {
                  let baseUserId = authInfo.AuthorizationStr.split("|")[0];
                  sessionStorage.setItem("baseUserId", baseUserId);
                }

                //储存解析后的请求头信息
                let authList = headStr.split("NewWedding ")[1].split(",");
                if (authList.length > 0) {
                  authList.forEach((auth) => {
                    if (auth.split("=")[0] != "") {
                      sessionStorage.setItem(
                        auth.split("=")[0],
                        auth.split("=")[1] ? auth.split("=")[1] : ""
                      );
                      localStorage.setItem(
                        auth.split("=")[0],
                        auth.split("=")[1] ? auth.split("=")[1] : ""
                      );
                    }
                  });
                }
                //储存店铺信息
                for (let key in authInfo.data) {
                  sessionStorage.setItem(key + "_B", authInfo.data[key]);
                  localStorage.setItem(key + "_B", authInfo.data[key]);
                }
                //存储im所需id
                sessionStorage.setItem("IMUserId", authInfo.IMUserId);
                localStorage.setItem("IMUserId", authInfo.IMUserId);
              }
              sessionStorage.setItem("versions", 2.0);
              localStorage.setItem("versions", 2.0);
              window.location = `${this.$getUrlPrefix('backstage',false)}`;
            },
            error(err) {
              this.$Error("操作失败");
            },
          });
      }
    },
    //打开侧边栏
    openSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    saveState() {
      sessionStorage.setItem("insercell", JSON.stringify(this.$store.state));
    },
    /**
     * 切换店铺
     */
    switchBranch(branch) {
      if (branch.BranchId == sessionStorage.getItem("BranchId")) {
        this.$Message("该店铺为当前所在店铺");
        return;
      }
      this.$axios({
        method: "POST",
        url: "NewWeddingApi/UserLogin/LoginMethod/UserLogin/ChangeUserBranch",
        data: {
          id: sessionStorage.getItem("UserId"), //	是	int	用户ID
          selectid: branch.BranchId, //	是	int	选中店ID
          device: 0, //是	int	pc传0
        },
        success(res) {
          if (res.data.status) {
            let Tablet = sessionStorage.getItem('Tablet');
            //记住选择的酒店精选非精选
            let showType = sessionStorage.getItem("hotelType") == "true";
            let ismac = localStorage.ismac != undefined && localStorage.ismac == "1" ? localStorage.ismac : '';
            const switchDisplayMode = getCaseDisplayMode('all'); // 获取案例列表展示方式
            sessionStorage.clear();
            localStorage.clear();
            if (Tablet) sessionStorage.setItem('Tablet',Tablet);
            setCaseDisplayMode(switchDisplayMode, 'all'); // 存储案例列表展示方式
            localStorage.setItem("ismac", ismac);

            sessionStorage.setItem("hotelType", showType);
            this.$store.state.warehouse = {
              keyName: '',
              label_shopId: 0, // 筛选 标签的店铺ID
              label: [],
              displayMode: -1, // 0 横图 、 1 竖图 2 表格
              fields: {}, // 字段
            }
            this.$store.state.discoverAuth = [];
            this.$store.state.macPath = "";
            // this.$store.state.authList = [];
            // this.$store.state.authButton = {};
            this.$store.state.userInfo = null;
            this.loginData = res.data;
            sessionStorage.setItem("isOutLogin", this.loginData.loginData);
            sessionStorage.setItem("UserName", this.loginData.nowusername);
            sessionStorage.setItem("jwtStr", this.loginData.jwtStr);
            localStorage.setItem("jwtStr", this.loginData.jwtStr);
            this.analysisAuthHead();
          } else {
            this.$Message(
              res.data.msg ? res.data.msg : "切换店铺失败",
              "error"
            );
          }
        },
        error(err) {
          console.log(err);
        },
      });
    },
    //请求头解析
    async analysisAuthHead() {
      let authInfo = this.loginData;
      //储存请求头信息
      let headStr = authInfo.Authorization;
      sessionStorage.setItem("Authorization", headStr);
      localStorage.setItem("Authorization", headStr);
      sessionStorage.setItem("hostAuthStr", authInfo.AuthorizationStr);
      localStorage.setItem("hostAuthStr", authInfo.AuthorizationStr);

      if (authInfo.AuthorizationStr) {
        let baseUserId = authInfo.AuthorizationStr.split("|")[0];
        sessionStorage.setItem("baseUserId", baseUserId);
      }

      //储存解析后的请求头信息
      let authList = headStr.split("NewWedding ")[1].split(",");
      if (authList.length > 0) {
        authList.forEach((auth) => {
          if (auth.split("=")[0] != "") {
            sessionStorage.setItem(
              auth.split("=")[0],
              auth.split("=")[1] ? auth.split("=")[1] : ""
            );
            localStorage.setItem(
              auth.split("=")[0],
              auth.split("=")[1] ? auth.split("=")[1] : ""
            );
          }
        });
      }
      //储存店铺信息
      for (let key in authInfo.data) {
        sessionStorage.setItem(key + "_B", authInfo.data[key]);
        localStorage.setItem(key + "_B", authInfo.data[key]);
      }
      //存储im所需id
      sessionStorage.setItem("IMUserId", authInfo.IMUserId);
      localStorage.setItem("IMUserId", authInfo.IMUserId);

      // 切换店铺肤色改变为当前店铺的默认肤色
      const theme = authInfo.userDetail.LookStyle || "Classic";
      sessionStorage.setItem('switchShopSkin', theme);

      // 默认肤色
      sessionStorage.setItem("DSkin", authInfo.userDetail.LookStyle || "Classic");
      localStorage.setItem("DSkin", authInfo.userDetail.LookStyle || "Classic");

      this.$store.newCos = window.cos;
      await this.getUserAuth(1);
      sessionStorage.setItem("insercell", JSON.stringify(this.$store.state));

      const urlObj = this.$getUrlPrefix(null,false);
      // 经典版
      if (theme === 'Classic' && process.env.NODE_ENV !== 'development') {
        window.location.href = `${urlObj['1.0']}case?v=${urlObj.version || '1.0'}`;
      } else {
        // 2.0
        location.reload();
      }
    },
    /**
     * 获取用户权限 并进入页面
     */
    getUserAuth(type = 1) {
      return new Promise((resolve, reject) => {
        this.$axios({
          url: 'NewWeddingApi/SystemModule/Module/Module/GetHomeModuleTreeNeedChild',
          method: 'POST',
          data: {
            moduleId: type === 1 ? '' : 0,
            category: type
          },
          success(res) {
            if (res.data.status) {
              if (type === 1) this.$store.state.authList = res.data.data;
              resolve(res.data.data);
            } else {
              this.$Message(res.data.msg, 'error');
              reject();
            }
          },
          error() {
            reject();
          }
        });
      });
    },
  },
};
</script>
<style lang="scss">
.home {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.home-menu {
  width: 100%;
  height: 72px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4; //关闭按钮层级要高于侧边栏

  .home-logo {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 6px 20px;
    box-sizing: border-box;
    .home-img {
      background-size: contain;
      height: 100%;
      width: 200px;
      background-repeat: no-repeat;
    }
    .logo-span {
      color: #ffffff;
      line-height: 60px;

      //font-size: 24px;
      //font-family: YouSheBiaoTiHei;

      // 繁体字效果出不来，改版成下面字体样式
      font-weight: 900;
      font-size: 20px;
    }
  }
  .menu-list {
    height: 100%;
    .menu-item {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      padding: 0 26px;
      cursor: pointer;
      transition: color 0.3s;
      span {
        position: relative;
        .new {
          width: 5px;
          height: 5px;
          background: #FF002B;
          border-radius: 3px;
          position: absolute;
          right: -6px;
          top: 2px;
        }
      }
      &:hover {
        color: #333;
        @include themify($themes) {
          color: themed("font-color-active");
        }
      }
    }
    .menu-item-active {
      font-size: 16px;
      font-weight: 600;
      color: #222222;
      @include themify($themes) {
        color: themed("font-color-active");
      }
      line-height: 28px;
      transition: color 0.3s;
    }
  }
}
.home-main {
  width: 100%;
  height: 100%;
  position: relative;
  @include themify($themes) {
    background: themed("main-bg");
  }
}
.mask-info {
	filter: brightness(0.8);
}
.mask-main {
  position: fixed;
  top: 72px;
  width: 100%;
  height: calc(100% - 72px);
  z-index: 1999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // backdrop-filter: blur(4px);
  cursor: pointer;
  .lock-box {
    width: 100px;
    height: 100px;
    background: #0093ff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon_suo {
      font-size: 48px;
      color: #ffffff;
    }
  }
  .lock-text {
    display: inline-block;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 22px;
    margin-top: 20px;
	text-shadow:1px 1px 4px #0000007a;
    @include themify($themes) {
      color: themed("font-color-active2")
    }
  }
}
</style>
<style scoped lang="scss">
.ishome-menu {
  background: transparent;
  .menu-list {
    .menu-item {
      color: rgba($color: #ffffff, $alpha: 0.6);
      @include themify($themes) {
        color: rgba($color: #ffffff, $alpha: 0.6);
      }
    }
    .menu-item-active {
      color: #ffffff;
      @include themify($themes) {
        color: themed("color-gou") !important;
      }
    }
  }
}
.qp-icon {
  width: 36px;
  height: 36px;
  line-height: 36px;
  margin-right: 20px;
}
.right-icon-box {
  right: 60px;
  top: 20px;
  position: fixed;
  display: flex;
  align-items: center;
}
.skin-div {
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  margin-right: 20px;
  display: inline-block;
  cursor: pointer;
  &:hover {
    background: $tab-color;
    color: $font-color-import2;
    @include themify($themes) {
      background: themed("hover-bg");
      color: themed("input-color");
    }
  }
}
img.skin {
  width: 24px;
  height: 24px;
  margin-top: 6px;
}
.head-sidebar {
  z-index: 9999; //关闭按钮层级要高于侧边栏
  display: block;
  right: 22px;
  top: 18px;
  color: #dedede;
  background: transparent;
  width: 36px;
  height: 36px;
  position: fixed;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  i {
    display: none;
  }
  &:after {
    content: "";
    display: block;
    width: 24px;
    height: 3px;
    background: #999999;
    position: absolute;
    top: 10px;
    right: 6px;
    transition: all 0.3s ease-out 0s;
  }
  &:before {
    content: "";
    display: block;
    width: 24px;
    height: 3px;
    background: #999999;
    position: absolute;
    bottom: 10px;
    right: 6px;
    transition: all 0.3s ease-out 0s;
  }
  &:hover {
    background-color: #444444;
    &:after {
      transform: rotate(45deg);
      top: 17px;
      background: #fff;
    }
    &:before {
      transform: rotate(-45deg);
      bottom: 16px;
      background: #fff;
    }
  }
}
.show-sidebar {
  background-color: #444444;
  &:after {
    transform: rotate(45deg);
    top: 19px;
    background: #fff;
  }
  &:before {
    transform: rotate(-45deg);
    bottom: 19px;
    background: #fff;
  }
}

@media screen and (max-width: 828px) {
  .menu-list {
    display: none;
  }
}

//日历弹窗样式
.calendarbox .el-dropdown-link {
  display: flex;
  align-items: center;
}
.calendarbox >>> .el-dialog__header {
  background: #fafbfc;
  border-bottom: 1px solid #f0f2f5;
  border-radius: 4px 4px 0 0;
  padding: 20px;
}
.calendarbox >>> .el-dialog {
  margin: auto !important;
  @include themify($themes) {
    background: #fff !important;
  }
}

//消息样式
/deep/ .dgbox .el-dialog__body {
  padding: 0;
}
/deep/ .dgbox .el-dialog__headerbtn {
  top: 2.15%;
  right: 1.9%;
  opacity: 0;
}
/deep/ .dgbox .el-dialog__header {
  padding: 0;
  display: none;
}
.iframe-calendar {
  border: none;
}
.shopp-iframe {
  background: #ffffff;
}
.sidebar-drawer {
  width: 400px;
}
.sidebar-drawer {
  width: 400px !important;
  @include themify($themes) {
    background: themed("search-bg");
  }

  & > .el-drawer__header {
    display: none;
  }
}
.dgbox {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.5);
  z-index: 2000;
}
/deep/ .dgbox .el-dialog {
  height: 768px;
  border-radius: 8px;
  overflow: hidden;
}
.sel-branch-div {

  .select-div {
    display: flex;
    align-items: center;
    cursor: pointer;
    &.item-open .icon_arrow_down {
      transform: rotate(180deg);
    }
  }
  .icon_arrow_down {
    color: #999;
    font-size: 24px;
    margin-left: 2px;
    transition: 0.2s;
  }
}
.option-div.nav {
  width: 100%;
  background: #333;
  flex-shrink: 0;
  padding: 10px 0;
  .option-item {
    line-height: 36px;
    width: 100%;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999;
    padding-left: 12px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 0;
    &:hover {
      background: #444444;
      color: #fff;
    }
    &.active {
      background: #444444;
      color: #ffcc00;
    }
    i {
      position: absolute;
      right: 12px;
      top: 0;
      font-size: 24px;
    }
  }
}
.rest-box {
  width: 320px;
  box-sizing: border-box;
  padding: 16px;
  &.q-rcode-xxn {
    height: auto;
    position: static;
    bottom: 0;
  }
  .title {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #222222;
    line-height: 20px;
    @include themify($themes) {
      color: themed("input-color");
    }
  }
  .p-t {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 18px;
    margin: 5px 0;
    margin-bottom: 10px;
  }
}
.rest-box.q-rcode-xxn {
  background: #fff;
  @include themify($themes) {
    background: themed("price-bg");
  }
}
</style>
<style>
.el-popover.nav-logo-name-popover {
  padding: 0;
  border: none;
}
.el-popover.nav-logo-name-popover[x-placement^=bottom] {
  margin-top: 0;
}
</style>
<style lang="scss">
.el-popper.rest-popper {
  margin-top: 20px;
  padding: 0;
  border-color: #fff;
  @include themify($themes) {
    border-color: themed("price-bg");
  }
}
.rest-popper.el-popper[x-placement^=bottom] .popper__arrow::after {
  border-width: 12px;
  top: -16px;
  margin-left: -16px;
  border-bottom-color: #fff;
  @include themify($themes) {
    border-bottom-color: themed("price-bg");
  }
}
.theme-Dark .rest-popper.el-popper[x-placement^=bottom] .popper__arrow,
.theme-Plus .rest-popper.el-popper[x-placement^=bottom] .popper__arrow {
  border-bottom-color: transparent;
}
.rest-btn {
  @include themify($themes) {
    border-radius: themed("border-radius-min");
  }
}
.theme-Plus,
.theme-Dark {
  .menu-list .menu-item span .new {
    background: #FFDD00;
  }
}
</style>
