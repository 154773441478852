<!-- /* * @Author: zbh * @Date: 2020-12-09 11:00:00 * @Last Modified by: zbh * @Last Modified time: * @Remark 小程序设置 */ -->
<template>
  <el-drawer
    :visible.sync="showSidebar"
    direction="rtl"
    @opened="initData"
    custom-class="sidebar-drawer"
    :append-to-body="false"
    :before-close="handleClose"
  >
    <div
      v-loading="isLoading"
      @click="
        isShowYowed = false;
        isLoading = false;
      "
      class="isLoading-main"
    >
      <div class="top-logo flex flex-js">
        <el-image
          class="top-img"
          :src="branchInfo.UserHeadImgSrc"
          fit="cover"
        ></el-image>
        <div class="top-name">{{ branchInfo.UserName }} <span class="main-user" v-if="branchInfo.IsZZH">主账号</span></div>
        <a
          class="head-sidebar show-sidebar"
          href="javascript:;"
          @click="handleClose"
          ><i class="fa fa-bars"></i
        ></a>
      </div>
      <div class="tab-list">
        <div
          class="tab-item flex flex-b"
          @click="tabChange(item)"
          v-Auth="{ type: 'rmenu', code: item.name, pCode: '' }"
          :class="{ 'tab-item-active': menuActive == item.name }"
          v-for="(item, index) in tabList"
          :key="index"
        >
          {{ item.value }}
          <i class="iconfont icon_lock" v-if="item.lock"></i>
          <i class="iconfont icon_arrow_right" v-else></i>
        </div>
      </div>
      <div class="tab-line"></div>
      <div class="tab-list">
        <el-popover
          placement="left-end"
          :visible-arrow="false"
          popper-class="xcx-menu-popover"
          :open-delay="200"
          :offset="-25"
          @show="isShowQrBox = true"
          @hide="isShowQrBox = false"
          trigger="hover"
        >
          <div class="xcx-menu-box">
            <el-popover
              placement="left"
              :visible-arrow="false"
              popper-class="xcx-menu-popover xcx-menu-qr"
              :open-delay="200"
              :offset="-48"
              @show="
                isShowQrcode = true;
                showCode1 = true;
                startCode();
              "
              @hide="isShowQrcode = false"
              trigger="hover"
              key="code-1"
            >
              <div class="qr-code-box height144" v-if="showCode1">
                <el-image
                  :src="codeSrcLogin ? codeSrcLogin : codeSrc"
                >
                  <div slot="placeholder" class="image-slot">
                    <el-image src="/images/05043120.gif"></el-image>
                  </div>
                </el-image>
              </div>
              <span class="menu-item" slot="reference">{{ codeSrcLogin ? '扫码进入我的小程序' : '扫码进入小程序' }}</span>
            </el-popover>
            <template  v-if="codeSrcLogin">
              <el-popover
                placement="left"
                :visible-arrow="false"
                popper-class="xcx-menu-popover xcx-menu-qr"
                :open-delay="200"
                :offset="-48"
                @show="
                  isShowQrcode = true;
                  showCode2 = true;
                  startCode();
                "
                @hide="isShowQrcode = false"
                trigger="hover"
                key="code-2"
              >
                <div class="qr-code-box" v-if="showCode2">
                  <el-image class="code-image" v-if="codeSrc" :src="codeSrc">
                    <div slot="placeholder" class="image-slot">
                      <el-image src="/images/05043120.gif"></el-image>
                    </div>
                  </el-image>
                  <div class="code-btn" @click="downCode" >下载高清二维码</div>
                </div>
                <span  class="menu-item"  slot="reference"
                  >扫码进入访客小程序</span
                >
              </el-popover>
            </template>
            <span class="menu-item"
               v-Auth="{
                type: 'btn',
                code: 'LittleProgram',
                pCode: 'Menu',
                rCode: '',
                isAdmin: isSetAuth
               }"
              @click="$emit('openWechatManage')">设置专享版小程序</span>
            <span class="menu-item" @click="$emit('openSetXcxMark')">小程序水印设置</span>
          </div>
          <div
            slot="reference"
            class="tab-item flex flex-b"
            :class="{
              'tab-item-hover': isShowQrBox || isShowQrcode,
            }"
          >
            小程序
          </div>
        </el-popover>

        <div
          class="tab-item flex flex-b"
          @click="tabChange(item)"
          v-Auth="{
            type: item.isNewPage ? 'rmenu' : 'btn',
            code: item.name && item.name === 'HotelManage' ? '' : item.name,
            pCode: item.isNewPage ? '' : 'Menu',
            rCode: '',
            isAdmin: isSetAuth,
          }"
          v-show="
           (
               item.name && item.name === 'HotelManage' &&
               authButton &&
               authButton['hotel'] &&
               !!authButton['hotel'].find(c => c.EnCode && c.EnCode.toLowerCase() === 'hotelauthority')
           ) || !(item.name && item.name === 'HotelManage')
          "
          v-for="(item, index) in tabList1"
          :key="index"
        >
          {{ item.value }}
          <i class="iconfont icon_lock" v-if="item.lock"></i>
          <i class="iconfont icon_arrow_right" v-else></i>
        </div>

        <div
            class="tab-item flex flex-b"
            @click="tabChange('stock')"
            v-show="branchType != '0'"
        >
          库存管理
          <i class="iconfont icon_arrow_right"></i>
        </div>
      </div>
      <div class="tab-line"></div>
      <div class="tab-list">
        <div class="tab-item flex flex-b" @click="isFunctionSetting = true">
          功能设置
          <i class="iconfont icon_arrow_right"></i>
        </div>
        <div class="tab-item flex flex-b" @click="isShowAccountSet = true">
          账号设置
          <i class="iconfont icon_arrow_right"></i>
        </div>
        <div class="tab-item flex flex-b" @click="isShowChangeSkin = true">
          换肤
          <i class="iconfont icon_arrow_right"></i>
        </div>
        <div class="tab-item flex flex-b" @click="$emit('vipRecord')" v-if="vipFileCountInfo.MyVipCount">
          开通的会员({{vipFileCountInfo.MyVipCount}})
          <i class="iconfont icon_arrow_right"></i>
        </div>
        <div class="tab-item flex flex-b" @click="$emit('worksPaymentRecord')" v-if="vipFileCountInfo.MyWorkFileCount">
          购买的作品({{vipFileCountInfo.MyWorkFileCount}})
          <i class="iconfont icon_arrow_right"></i>
        </div>
        <div class="tab-item flex flex-b" @click="$emit('worksOrderRecord')" v-if="vipFileCountInfo.BuyRecordCount">
          下单记录({{vipFileCountInfo.BuyRecordCount || 0}})
          <i class="iconfont icon_arrow_right"></i>
        </div>
<!--        <div class="tab-item flex flex-b" @click="isShowMoerMenu = true">-->
<!--          更多-->
<!--          <i class="iconfont icon_arrow_right"></i>-->
<!--        </div>-->
      </div>
      <div class="tab-line"></div>
      <div class="expire-info-box">
        <div
          class="expire-info"
          :class="{
            'expire-info-dark':
              $store.state.theme == 'Dark' || $store.state.theme == 'Plus',
          }"
        >
          <div class="expire-top">
            <div>
              <span class="version-text">{{ branchInfo1.BranchVer }}</span>
              <span class="expire-time">{{
                branchInfo1.ExDay >= 0
                  ? `还有${branchInfo1.ExDay}天到期`
                  : `已到期${-branchInfo1.ExDay}天`
              }}</span>
            </div>

            <div style="display: flex">
              <div class="btn-xf" @click="$bus.$emit('showRemind', 'Renew')">
                续费
              </div>
              <div
                class="btn-xf"
                v-if="!branchInfo1.NowVerIsMax&&branchInfo1.ExDay>=0"
                @click="$bus.$emit('showRemind', 'Upgrade')"
              >
                升级
              </div>
            </div>
          </div>

          <div class="expire-main">
            <div class="team-info">
              <span class="team-num">
                团队人数：{{
                  branchInfo1.UsePeople ? branchInfo1.UsePeople : 0
                }}/{{ branchInfo1.TotalPeople ? branchInfo1.TotalPeople : 0 }}
              </span>
              <span
                class="team-num team-shop"
                v-if="branchInfo1.ExDay >= 0"
                @click.stop="$bus.$emit('showRemind', 'PurchaseBuy')"
              >
                购买人数
              </span>
            </div>
            <div class="team-info">
              <span class="team-num">
                所用空间：{{ branchInfo1.UsedCapacityRemark }}/{{
                  branchInfo1.TotalCapacityRemark
                }}
              </span>
              <div>
                <span class="team-num team-shop" @click="$emit('useSpaceDig')"
                  >查看明细</span
                >
                <span
                  class="team-num team-shop"
                  v-if="branchInfo1.ExDay >= 0"
                  @click.stop="$bus.$emit('showRemind', 'PurchaseBuy')"
                  >购买空间</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isShowYowed" class="q-rcode-xxn" @click.stop>
        <p class="qrcode-text1">请联系客服开通/续费</p>
        <div class="qrcode-content1">
          <span class="yowed-tip">扫码添加我的企业微信</span>
          <img class="youwed-qrcode" src="images/home/yowed-ewm.png" alt />
          <span class="yowed-name">有婚系统YoWed小仙女</span>
          <span class="yowed-tip1">四川蓝海助梦科技有限公司</span>
          <span class="yowed-tip1">YoWed首席体验官</span>
        </div>
      </div>

      <!-- 账号设置 -->
      <transition name="friend-fade"
        ><accountSet
          v-if="isShowAccountSet"
          @closeAddDig="isShowAccountSet = false"
        ></accountSet
      ></transition>
      <!-- 换肤 -->
      <transition name="friend-fade"
        ><changeSkin
          v-if="isShowChangeSkin"
          @closeAddDig="isShowChangeSkin = false"
        ></changeSkin
      ></transition>
      <!-- 更多 -->
      <transition name="friend-fade"
        ><MoerMenu
          v-if="isShowMoerMenu"
          :isSetAuth="isSetAuth"
          @tabChange="tabChange"
          @openInquiryLog="$emit('openInquiryLog')"
          @closeAddDig="isShowMoerMenu = false"
        ></MoerMenu
      ></transition>

      <!-- 更多 -->
      <transition name="friend-fade"
      ><functionSet
          v-if="isFunctionSetting"
          :isSetAuth="isSetAuth"
          @tabChange="tabChange"
          @openInquiryLog="$emit('openInquiryLog')"
          @closeFunctionSet="isFunctionSetting = false"
      ></functionSet
      ></transition>

      <!--   被锁提示   -->
      <ConTip
        @closeTipDig="isShowTip = false"
        @confirmClick="confirmClick"
        :isShowCancel="false"
        type="warn"
        tip-text="请升级更高版本体验此功能"
        :isShowTip="isShowTip"
        confirmText="立即升级"
      ></ConTip>
    </div>
  </el-drawer>
</template>

<script>
import functionSet from "./functionSet";
import MoerMenu from "./moerMenu"; //更多操作
import accountSet from "./accountSet"; //账号设置
import changeSkin from "./changeSkin";
import {mapActions, mapState} from "vuex";
import {imgDownLoad} from "@/assets/js/utils/downImg";

export default {
  data() {
    return {
      showCode1: false,
      showCode2: false,
      isShowTip: false,
      isFunctionSetting: false, // 功能设置
      codeSrc: "",
      codeSrcLogin: "",
      isBaseLoad: false,
      isShowQrBox: false,
      isShowQrcode: false,
      isShowChangeSkin: false,
      isShowAccountSet: false,
      isShowMoerMenu: false, //是否显示更多菜单
      isShowYowed: false, //是否显示二维码
      isSetAuth: false,
      tabList: [
        {
          value: "首页",
          name: "company",
        },
        {
          value: "作品",
          name: "case",
        },
        {
          value: "酒店",
          name: "hotel",
        },
        {
          value: "金刚/资源",
          name: "team",
        },
        {
          value: "灵感",
          name: "inspira",
          isNewPage: true,
        },
        {
          value: "后台",
          name: "backManage",
          isNewPage: true,
        },
        {
          value: "商城",
          name: "shopping",
        },
      ],
      tabList1: [
        {
          value: "好友管理",
          name: "FriendsMan",
        },
        {
          value: "酒店管理",
          name: "HotelManage",
          authName: 'hotelauthority',
        },
        {
          value: "我的网盘",
          name: "Disk",
          isNewPage: true,
        },
      ],
      isShowEditInfo: false,
      isLoading: false,
      showSide: false,
      totalSize: 0,
      useSize: 0,
      useScale: 0,
      vipFileCountInfo: {
        MyVipCount: 0, // 购买会员数量
        MyWorkFileCount: 0, //  购买作品数量
      },
    };
  },
  props: {
    showSidebar: {
      type: Boolean,
      default: false,
    },
    menuActive: {
      type: String,
      default: "",
    },
  },
  components: {
    accountSet,
    changeSkin,
    MoerMenu,
    functionSet
    // Register,
    // EditInfo
  },
  watch: {
    showSidebar(val) {
      // if (val) {
      this.showSide = val;
      // } else {
      // 	this.$emit('closeSidebarDig');
      // }
    },
  },
  created() {},
  mounted() {
    this.getGetUserInfo();
    let _this = this;
    //显示续费、升级、到期提醒等弹窗
    this.$bus.$on("refreshRemind", () => {
      _this.getBranchFileSize();
    });
    this.getAuthorizeByIds1();
    this.getAuthLock(); // 获取权限锁问题
  },
  beforeDestroy() {
  },
  computed: {
    ...mapState({
      branchInfo: (state) => state.base.branchInfo,
      branchInfo1: (state) => state.useSpaceInfo,
      authList: state => state.authList, // 权限
      branchType: state => state.branchType,
      authButton: state => state.authButton, // 按钮权限
    }),
  },
  methods: {
    ...mapActions({
      getGetUserInfo: "base/getGetUserInfo",
    }),
    // 获取当前机构 会员购买数量和文件购买数量
    getMyShopVipFileCountInfo() {
      this.$axios({
        url: process.env.VUE_APP_TEAMURL + "xlapi/MemberManage/MenberPayCallback/GetMyShopVipFileCountInfo",
        method: "POST",
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        success(res) {
          if (res && res.data.status) {
            this.vipFileCountInfo = res.data.data || {};
          }
        },
        error(err) {
          console.log(err);
        },
      })
    },
    // 下载高清二维码
    downCode(){
      const path = `${this.codeSrc}&width=800`
      let filename = `小程序高清二维码`;
      console.log(path,'数据')
      //壳子ismac
      if (localStorage.ismac != undefined && localStorage.ismac == '1') {
        var downloadmsg = '{"actiontype":"outdate","fileurl":"' + path + '","filename":"' + filename + '.png"}';
        window.webkit.messageHandlers.AppModel.postMessage(downloadmsg);
        return;
      }
      imgDownLoad(filename, path,false);
    },
    /**
     * 被锁提示弹窗 立即升级按钮
     */
    confirmClick() {
      // 升级版本
      this.$bus.$emit('showRemind', 'Upgrade');
    },
    getBtnAuth(authName) {
      const authButton = this.$store.state.authButton || {};
      if (authButton && authButton[authName] && authButton[authName].length > 0) return;
      const moduleRow = this.$store.state.authList.find(m => m.path && m.path.toLowerCase() === '/' + authName);
      if (moduleRow && moduleRow.id) {
        this.$axios({
          url: "NewWeddingApi/SystemModule/Module/Module/GetModuleButtonList",
          method: "POST",
          data: {
            moduleId: moduleRow.id
          },
          success(res) {
            if (res && res.data.status) {
              let aa = {
                ...this.$store.state.authButton
              }
              aa[authName] = res.data.data;
              this.$store.state.authButton = aa;
            }
          },
          error(err) {
            console.log(err);
          },
        })
      }
    },
    /**
     * 获取权限锁问题
     */
    getAuthLock() {
      const list = [...this.tabList, ...this.tabList1];
      const userAuth = !this.authList || this.authList.length <= 0 ? this.$store.state.authList : this.authList;
      list.forEach(c => {
        const item = userAuth.find(ua => ua.value.toLowerCase() === c.name.toLowerCase());
        if (item) {
          c.lock = !item.Power;
        }
      })
    },
    // themeChange() {
    //   if (this.$store.state.theme == "Min") {
    //     this.$store.state.theme = "Dark";
    //   } else {
    //     this.$store.state.theme = "Min";
    //   }
    //   sessionStorage.setItem("insercell", JSON.stringify(this.$store.state));
    // },
    /**
     *获取是否有管理员权限
     */
    getAuthorizeByIds1() {
      this.$axios({
        method: "POST",
        // url: "NewWeddingApi/AuthorizeModule/Authorize/GetAuthorizeByIds",
        url: 'NewWeddingApi/AuthorizeModule/Authorize/IsAuth',
        data: {
          itemid: "9951556E-3026-48EC-AF2E-0600CA066C3B",
        },
        success: (res) => {
          if (res.data.status) {
            // let userList = res.data.data;
            // this.isSetAuth = !!userList.find(
            //   (u) => u.userid == sessionStorage.getItem("UserId")
            // );
            // sessionStorage.setItem('isSetAuth', this.isSetAuth);
            this.isSetAuth = !!res.data.data;
            sessionStorage.setItem('isSetAuth', this.isSetAuth);
          } else {
            this.$Error(res.data.msg ? res.data.msg : "操作失败");
          }
        },
        error(err) {
          console.log(err);
          this.$Error("操作失败");
        },
      });
    },
    /**
     * 获取店铺文件大小
     */
    getBranchFileSize() {
      this.$axios({
        method: "POST",
        url: "NewWeddingApi/FileManage/FileManage2.0/FileNew/GetBranchPathStaticsInfo",
        success(res) {
          if (res.data.status) {
            let totalSize = res.data.data.TotalCapacity || 0;
            let useSize = res.data.data.UsedCapacity || 0;
            this.$store.state.useSpaceInfo = res.data.data;
            let useScale = parseFloat((useSize / totalSize).toFixed(4));
            this.totalSize = parseFloat((totalSize / 1024 / 1024).toFixed(2));
            if (this.totalSize >= 1024) {
              this.totalSize =
                parseFloat((this.totalSize / 1024).toFixed(2)) + "G";
            } else {
              this.totalSize += "M";
            }
            this.useSize = parseFloat((useSize / 1024 / 1024).toFixed(2));
            if (this.useSize >= 1024) {
              this.useSize = parseFloat((this.useSize / 1024).toFixed(2)) + "G";
            } else {
              this.useSize += "M";
            }
            this.useScale = useScale;
          }
        },
        error(err) {
          console.log(err);
        },
      });
    },
    initData() {
      this.getBtnAuth('hotel');
      this.startCode();
      this.getMyShopVipFileCountInfo();
      this.$bus.$on('refreshMyShopVipFileCountInfo', this.getMyShopVipFileCountInfo);
    },
    startCode() {
      this.$axios({
        method: "get",
        url: `${process.env.VUE_APP_TEAMURL}xlapi/HostManage/HostUserManage/HostThree/GetIsRelease`,
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
        success(res) {
          if (res.data.data) {
            this.codeSrc = `${process.env.VUE_APP_APPLET_CODE}xlapi/HostManage/HostUserManage/HostSmall/GetStartCode?content=1&authAppId=${res.data.data}&isHyaline=false`;
            this.codeSrcLogin = `${process.env.VUE_APP_APPLET_CODE}xlapi/HostManage/HostUserManage/HostSmall/GetStartCode?content=isPCLogin&authAppId=${res.data.data}&isHyaline=false`;
          } else {
            this.codeSrc = `${process.env.VUE_APP_APPLET_CODE}xlapi/HostManage/HostUserManage/BaseUser/GetStartCode?content=&scence=&isHyaline=false`;
          }
          this.isBaseLoad = true;
        },
      });
    },
    // /**
    //  * 进入网盘
    //  */
    // toMyDiskPage() {
    //   let jwtoken = sessionStorage.getItem("Authorization");
    //   sessionStorage.setItem("versions", 2.0);
    //   localStorage.setItem("versions", 2.0);
	  // const ismac = localStorage.getItem("ismac") != undefined && localStorage.getItem("ismac") == '1' ? '1' : '';
    //   let locations = `${process.env.VUE_APP_PAGEURL}Function/Networks/index.html?jwtoken=${jwtoken}&ismac=${ismac}`;
    //   window.location = locations;
    // },
    tabChange(val) {
      if (val.val === 'customCaseTemplateList') { // 自定义作品模板管理
        this.$emit("openCustomCaseTemplateList");
        return;
      }
      // 库存管理
      if (val === 'stock') {
        this.$emit("openStock");
        return;
      }
      // 被锁
      if (val && val.lock) {
        this.isShowTip = true;
        return;
      }
      if (val.value == "公司页（首页）自定义") {
        this.$router.push({ name: "functionSet" });
      } else if (val.value == "好友管理") {
        //打开好友管理
        this.$emit("openFriendManage");
      } else if (val.value == "商城") {
        //打开商城页面
        this.$emit("openShopping");
      } else if (val.value == "酒店管理") {
        //打开酒店页面
        this.$emit("openHotelManage");
      } else if (val.value == "用户管理") {
        //打开用户管理页面
        this.$emit("openUserManage");
      } else if (val.value == "权限设置") {
        //打开权限设置页面
        this.$emit("openAuthSetting");
      } else if (val.value === '音乐库管理') {
        this.$emit('openMusicLibrary');
      } else if (val.value === '酒店标签设置') {
        this.$emit('openHotelLabelManage')
      } else if (val.value === '小程序作品水印设置') {
        this.$emit('openSetXcxMark')
      } else {
        if (this.menuActive == val.name) {
          return;
        }
        if (val.name && !val.isNewPage) {
          // this.$router.push({ name: val.name });
          // this.menuActive = val.name;
          this.$router.push({ name: val.name });
          this.$emit("tabSubmit", val.name);
        } else if (val.name === "inspira") {
          this.$router.push('/inspira');
        } else if (val.name === "backManage") {
          // 后台
          sessionStorage.setItem("versions", 2.0);
          localStorage.setItem("versions", 2.0);
          window.location = `${this.$getUrlPrefix('backstage',false)}`;
        } else if (val.name === "Disk") {
          // 网盘
          sessionStorage.setItem("versions", 2.0);
          localStorage.setItem("versions", 2.0);
		      const ismac = localStorage.getItem("ismac") != undefined && localStorage.getItem("ismac") == '1' ? '1' : '';
          let jwtoken = sessionStorage.getItem("Authorization");
          const urlObj = this.$getUrlPrefix(null,false);

          let locations = `${urlObj['Networks']}?jwtoken=${jwtoken}&ismac=${ismac}&v=${urlObj.version || '1.0'}`;
          // let locations = `${process.env.VUE_APP_PAGEURL}Function/Networks/index.html?jwtoken=${jwtoken}&ismac=${ismac}`;
          window.location = locations;
        }
      }
    },
    //关闭
    handleClose() {
      this.$bus.$off('refreshMyShopVipFileCountInfo', this.getMyShopVipFileCountInfo);
      this.$emit("closeSidebarDig");
      //关闭其他二级页面
      setTimeout(() => {
        this.isShowAccountSet = false;
        this.isShowChangeSkin = false;
        this.isShowMoerMenu = false;
        this.isFunctionSetting = false;
      });
    },
  },
};
</script>
<style lang="scss">
.friend-fade-enter-active {
  transition: all 0.4s ease;
}

.friend-fade-leave-active {
  transition: all 0.4s ease;
}

.friend-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.friend-fade-enter {
  transform: translateX(100%);
  opacity: 0;
}
.xcx-menu-popover {
  width: 160px;
  background: #ffffff !important;
  border-color: #ffffff !important;
  @include themify($themes) {
    background: themed("search-his-bg1") !important;
    border-color: themed("search-his-bg1") !important;
  }
  padding: 8px 0 !important;
  border-radius: 0 !important;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2) !important;
  .menu-item {
    display: inline-block;
    width: 100%;
    height: 48px;
    line-height: 48px;
    padding: 0 16px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include themify($themes) {
      color: themed("hotel-color");
    }
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    &:hover {
      background: #eeeeee;
      color: #222222;
      @include themify($themes) {
        background: themed("search-his-bg-h");
        color: themed("input-color");
      }
    }
  }

  .qr-code-box {
    width: 100%;
    height: 200px;
    padding: 0 8px;
    box-sizing: border-box;
    .el-image {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .code-image {
      width: 100%;
      height: 144px;
    }
    .code-btn{
      padding: 10px;
      border: 1px solid #EEEEEE;
      cursor: pointer;
      transition: all 0.3s;
      margin-top: 10px;
      text-align: center;
      font-size: 12px;
      &:hover {
        background: #444444;
        border-color: #444444;
        color: #FFFFFF;
      }
    }
  }
  .height144 {
  	   height: 144px;
	   .code-image {
	     width: 100%;
	     height: 100%;
	   }
  }
}
.xcx-menu-qr {
  @include themify($themes) {
    background: #ffffff !important;
  }
}
</style>
<style lang="scss">
.sidebar-drawer {
  width: 400px !important;
  @include themify($themes) {
    background: themed("search-bg");
  }
  & > .el-drawer__header {
    display: none;
  }
  .isLoading-main {
    height: 100vh;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }
  }
  .top-logo {
    padding: 24px 0;
    margin: 0 24px;
    border-bottom: 1px solid #eeeeee;
    background: #fff;
    @include themify($themes) {
      background: themed("search-bg");
      border-bottom: 1px solid themed("search-his-bg-h");
    }
    position: sticky;
    top: 0;
    z-index: 2;
    .top-img {
      width: 36px;
      height: 36px;
      border-radius: 100%;
      margin-right: 8px;
    }
    .top-name {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      line-height: 26px;
      flex: 1;
      @include themify($themes) {
        color: themed("input-color");
      }
      .main-user {
        font-size: 12px;
        color: #999;
        padding: 1px 4px;
        margin-left: 2px;
        border: 1px solid #ccc;
        vertical-align: bottom;
        @include themify($themes) {
          border-radius: themed('border-radius-min');
          border-color: themed("custom-color-bg");
        }
      }
    }
    .head-sidebar {
      // z-index: 9999; //关闭按钮层级要高于侧边栏
      display: block;
      right: 24px;
      top: 24px;
      color: #dedede;
      background: transparent;
      width: 36px;
      height: 36px;
      position: fixed;
      transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
      i {
        display: none;
      }
      &:after {
        content: "";
        display: block;
        width: 24px;
        height: 3px;
        background: #999999;
        position: absolute;
        top: 17px;
        right: 6px;
        transition: all 0.3s ease-out 0s;
      }
      &:before {
        content: "";
        display: block;
        width: 24px;
        height: 3px;
        background: #999999;
        position: absolute;
        bottom: 16px;
        right: 6px;
        transition: all 0.3s ease-out 0s;
      }
    }
    .show-sidebar {
      background-color: #444444;
      &:after {
        transform: rotate(45deg);
        // top: 15px;
        background: #fff;
      }
      &:before {
        transform: rotate(-45deg);
        // bottom: 15px;
        background: #fff;
      }
    }
  }
}
.tab-line {
  border-bottom: 1px solid #eeeeee;
  @include themify($themes) {
    border-bottom: 1px solid themed("cancel-btn-border");
  }
  margin: 12px 24px;
}
.tab-list {
  .tab-item {
    display: flex;
    justify-content: space-between;
    padding: 14px 24px 14px 40px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    @include themify($themes) {
      color: themed("hotel-color");
    }
    line-height: 20px;
    cursor: pointer;
    .icon_lock {
      color: #999;
      font-size: 16px;
    }
    .icon_arrow_right {
      font-size: 16px;
      opacity: 0;
    }
    &:hover {
      background: #eeeeee;
      @include themify($themes) {
        background: themed("cancel-btn-border");
      }
      .icon_arrow_right {
        opacity: 1;
      }
    }
  }
  .tab-item-noHover {
    display: flex;
    justify-content: space-between;
    padding: 14px 24px 14px 40px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    @include themify($themes) {
      color: themed("hotel-color");
    }
    line-height: 20px;
    cursor: pointer;
    .icon_lock {
      color: #999;
      font-size: 16px;
    }
    .icon_arrow_right {
      font-size: 16px;
      opacity: 0;
    }
  }
  .tab-item-hover {
    background: #eeeeee;
    @include themify($themes) {
      background: themed("search-his-bg-h");
    }
    .icon_arrow_right {
      opacity: 1;
    }
  }
  .tab-item-active {
    color: #222222;
    @include themify($themes) {
      color: themed("search-btn-bg");
    }
    font-weight: 500;
    position: relative;
    &::before {
      content: "";
      background: #222222;
      @include themify($themes) {
        background: themed("search-btn-bg");
      }
      position: absolute;
      width: 8px;
      height: 2px;
      top: 50%;
      margin-top: -1px;
      left: 24px;
    }
  }
}
.account-header-w {
  padding: 24px;
  height: 80px;
  border-bottom: 1px solid #dddddd;
  @include themify($themes) {
    border-bottom: 1px solid themed("search-his-bg-h");
  }
  box-sizing: border-box;
  .account-header {
    display: flex;
    align-items: center;
    font-size: 18px !important;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    @include themify($themes) {
      color: themed("input-color");
    }
    line-height: 26px;
    .icon_arrow_left {
      font-size: 24px;
      margin-right: 12px;
      background: transparent;
      cursor: pointer;
      @include themify($themes) {
        border-radius: themed("border-radius-min");
      }
      &:hover {
        @include themify($themes) {
          background: themed("cancel-btn-border");
        }
      }
    }
  }
}
.accountSet-main .el-loading-mask {
  @include themify($themes) {
    background: themed("loading-bg");
  }
}
.expire-info-box {
  padding: 24px 0;
  position: sticky;
  bottom: 0;
  background: #ffffff;
  @include themify($themes) {
    background: themed("lable-bg");
  }
}
.expire-info {
  width: calc(100% - 40px);
  height: 120px;
  background: linear-gradient(135deg, #434854 0%, #232733 100%);
  margin: 0 auto;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .expire-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .version-text {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #f2d6b6;
      line-height: 24px;
    }
    .expire-time {
      display: inline-block;
      height: 20px;
      background: linear-gradient(135deg, #fee8cd 0%, #eabf98 100%);
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #664433;
      line-height: 20px;
      margin-left: 4px;
      padding: 0 8px;
      box-sizing: border-box;
    }
  }
  .btn-xf {
    color: #333333;
    font-size: 14px;
    background: linear-gradient(135deg, #fde7cc 0%, #ddb691 100%);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 2px 8px;
    cursor: pointer;
    margin: 4px 0;
    height: 24px;
    line-height: 24px;
    flex-shrink: 0;
    margin-left: 8px;
    &:hover {
      text-decoration: underline;
    }
  }
  .expire-main {
    .team-info {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      .team-num {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #d2bfb4;
        line-height: 20px;
      }
      .team-shop {
        cursor: pointer;
        margin-left: 8px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.expire-info-dark {
  background: #33302e;
  border-radius: 6px;
  .expire-top {
    .version-text {
      color: #fee8cd;
    }
    .expire-time {
      border-radius: 2px;
      border: 1px solid #b3a397;
      background: transparent;
      color: #b3a397;
    }
  }
  .expire-main .team-num {
    color: #b3a397;
  }
}
.q-rcode-xxn {
  width: 180px;
  height: 280px;
  background: #ffffff;
  box-shadow: 0px 10px 40px 0px rgba(38, 51, 77, 0.15);
  position: absolute;
  margin: auto;
  top: 0;
  right: 110px;
  bottom: 0;
  box-sizing: border-box;
  padding: 10px;
  z-index: 9999;
  .qrcode-text1 {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 20px;
  }
  .qrcode-content1 {
    width: 160px;
    height: 230px;
    margin-top: 10px;
    text-align: center;
    background: linear-gradient(135deg, #33aaff 0%, #006aff 100%);
    .yowed-tip {
      display: inline-block;
      width: 100%;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 24px;
    }
    .youwed-qrcode {
      width: 120px;
      height: 120px;
      object-fit: contain;
      margin: 7px auto 0;
    }
    .yowed-name {
      display: inline-block;
      width: 100%;
      text-align: center;
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 16px;
      margin-top: 8px;
    }
    .yowed-tip1 {
      display: inline-block;
      width: 100%;
      text-align: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #cee3ff;
      line-height: 16px;
    }
  }
  .qrcode-content2 {
    width: 100%;
    height: 230px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
    .branch-item {
      display: inline-block;
      width: 100%;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 40px;
      cursor: pointer;
      padding: 0 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;
      position: relative;
      .active-branch {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 18px;
        padding: 0 4px;
        background: #333333;
        border-radius: 2px;
        margin-left: 4px;
      }
      .branch-icon {
        position: absolute;
        right: 20px;
        top: 13px;
      }
      &:hover {
        background: #eeeeee;
      }
    }
  }
  .branch-tips {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 20px;
    margin: 20px 0 10px 20px;
    display: inline-block;
  }
}
</style>
